import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Trivut Site Pages
import HomePage from "./trivut/pages/HomePage";
import Careers from "./trivut/pages/Careers";
import DocumentTrackingSystem from "./trivut/pages/DocumentTrackingSystem";
import InvestorCenter from "./trivut/pages/InvestorCenter";
import OurExpertise from "./trivut/pages/OurExpertise";
import OutsourcePage from "./trivut/pages/OutsourcePage";
import PrivacyPolicy from "./trivut/pages/PrivacyPolicy";
import TermsAndConditions from "./trivut/pages/TermsAndConditions";
import TitlesAndDescriptions from "./trivut/components/TitlesAndDescriptions";
// import TogoToken from "./trivut/pages/TogoToken";
import ErrorPage from "./trivut/pages/ErrorPage";

// Trivut Dashboard Pages
import Login from "./dashboard/pages/login";
import Dashboard from "./dashboard/pages/dashboard";
import Projects from "./dashboard/pages/projects";
import Notes from "./dashboard/pages/notes";
import Calendar from "./dashboard/pages/calendar";
import ReporAndAnalytics from "./dashboard/pages/reportsAndAnalytics";
import TraineeAttendance from "./dashboard/pages/traineeAttendance";
import TraineeProgressTracker from "./dashboard/pages/traineeProgressTracker";
import TraineeProjects from "./dashboard/pages/traineeProjects";

function App() {
  return (
    <Router>
      <Routes>
        {/* Trivut Main Site Routes */}
        <Route
          path="/"
          element={
            <>
              <TitlesAndDescriptions page="home" />
              <HomePage />
            </>
          }
        />
        <Route
          path="careers"
          element={
            <>
              <TitlesAndDescriptions page="careers" />
              <Careers />
            </>
          }
        />
        <Route
          path="document-tracking-system"
          element={
            <>
              <TitlesAndDescriptions page="dts" />
              <DocumentTrackingSystem />
            </>
          }
        />
        <Route
          path="investor-center"
          element={
            <>
              <TitlesAndDescriptions page="investor-center" />
              <InvestorCenter />
            </>
          }
        />
        <Route
          path="our-expertise"
          element={
            <>
              <TitlesAndDescriptions page="expertise" />
              <OurExpertise />
            </>
          }
        />
        <Route
          path="outsourcing"
          element={
            <>
              <TitlesAndDescriptions page="outsourcing" />
              <OutsourcePage />
            </>
          }
        />
        <Route
          path="privacy-policy"
          element={
            <>
              <TitlesAndDescriptions page="privacy-policy" />
              <PrivacyPolicy />
            </>
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <>
              <TitlesAndDescriptions page="terms-and-conditions" />
              <TermsAndConditions />
            </>
          }
        />
        {/* <Route
          path="/togo-token"
          element={
            <>
              <TitlesAndDescriptions page="togo-token" />
              <TogoToken />
            </>
          }
        /> */}

        {/* Trivut Dashboard Routes */}
        <Route
          path="/login"
          element={
            <>
              <TitlesAndDescriptions page="dashboard-login" />
              <Login />
            </>
          }
        />
        <Route
          path="dashboard"
          element={
            <>
              <TitlesAndDescriptions page="dashboard-home" />
              <Dashboard />
            </>
          }
        />
        <Route
          path="dashboard/projects"
          element={
            <>
              <TitlesAndDescriptions page="dashboard-projects" />
              <Projects />
            </>
          }
        />
        <Route
          path="dashboard/notes"
          element={
            <>
              <Notes />
            </>
          }
        />
        <Route
          path="dashboard/calendar"
          element={
            <>
              <TitlesAndDescriptions page="dashboard-calendar" />
              <Calendar />
            </>
          }
        />
        <Route
          path="dashboard/reports-and-analytics"
          element={
            <>
              <TitlesAndDescriptions page="dashboard-reports" />
              <ReporAndAnalytics />
            </>
          }
        />
        <Route
          path="dashboard/training/attendance"
          element={
            <>
              <TitlesAndDescriptions page="dashboard-attendance" />
              <TraineeAttendance />
            </>
          }
        />
        <Route
          path="dashboard/training/progress-tracker"
          element={
            <>
              <TitlesAndDescriptions page="dashboard-progress-tracker" />
              <TraineeProgressTracker />
            </>
          }
        />
        <Route
          path="dashboard/training/projects"
          element={
            <>
              <TitlesAndDescriptions page="dashboard-progress-tracker" />
              <TraineeProjects />
            </>
          }
        />
        <Route
          path="*"
          element={
            <>
              <TitlesAndDescriptions page="error-page" />
              <ErrorPage />
            </>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
