import { useState, useRef } from "react";

// import Reaptcha from "reaptcha";

import emailAddress1 from "../img/about/email3.webp";

const ContactForm = () => {
  // const [captchaToken, setCaptchaToken] = useState(null);
  // const [isVerified, setIsVerified] = useState(false);
  // const captchaRef = useRef(null);

  // const verify = () => {
  //   captchaRef.current.getResponse().then((res) => {
  //     setCaptchaToken(res);
  //     setIsVerified(true);
  //   });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div id="contact" className="contact-area">
        <div className="contact-inner area-padding">
          <div className="contact-overly"></div>
          <div className="container ">
            <div className="row">
              <div className="col-12">
                <div className="section-headline text-center">
                  <h2>Connect with Us</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="contact-icon text-center">
                  <div className="single-icon">
                    <i className="bi bi-phone"></i>
                    <p>
                      Call: +63 961 560 1730
                      <br />
                      <span>Monday-Friday (8am-5pm)</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contact-icon text-center">
                  <div className="single-icon">
                    <i className="bi bi-envelope"></i>
                    <p>
                      Email:{" "}
                      <img
                        src={emailAddress1}
                        alt="email"
                        width="128px"
                        height="20px"
                        style={{ paddingBottom: "2px" }}
                      />
                      <br />
                      <span>Web: https://www.trivut.com</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contact-icon text-center">
                  <div className="single-icon">
                    <i className="bi bi-geo-alt"></i>
                    <p>
                      Location: Santa Cruz, Palo
                      <br />
                      <span>Leyte 6501, PH</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11148.680819584491!2d124.98408217632387!3d11.155943417215248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3307d88f5f9a0c95%3A0x648512157d94d47!2sSanta%20Cruz%2C%20Palo%2C%20Leyte!5e0!3m2!1sen!2sph!4v1664155565065!5m2!1sen!2sph"
                  loading="lazy"
                  width="100%"
                  height="307"
                  title="Trivut Location"
                  frameBorder="0"
                  style={{ border: "0" }}
                  allowFullScreen
                ></iframe>
              </div>

              <div className="col-md-6">
                <div className="form contact-form">
                  <form onSubmit={handleSubmit}>
                    <div
                      className="form-group"
                      class="g-recaptcha"
                      data-sitekey="6LfMaKciAAAAAP2TZolJnY4jxhdkMYqy-xvdFs33"
                    >
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div className="form-group mt-3">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                    <div className="form-group mt-3">
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        id="subject"
                        placeholder="Subject"
                        required
                      />
                    </div>
                    <div className="form-group mt-3">
                      <textarea
                        className="form-control"
                        name="message"
                        rows="3"
                        placeholder="Message"
                        required
                      ></textarea>
                    </div>
                    <div className="d-flex justify-content-center mt-2">
                      {/* <Reaptcha
                        sitekey="6Lf7XewZAAAAAIR4oxQDZ4dR_KdfIdg_ais-QGIv"
                        ref={captchaRef}
                        onVerify={verify}
                      /> */}
                    </div>

                    <div className="text-center mt-1">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        // disable={isVerified}
                      >
                        Send Us a Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
