import { Link } from "react-router-dom";
import { useState } from "react";

import trivutLoginImg from "../assets/img/curved-images/trivut_login_img.png";

import { IconContext } from "react-icons";
import { AiFillHome } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";
import { AiFillLinkedin } from "react-icons/ai";
// import { MdEmail } from "react-icons/md";

const Login = () => {
  return (
    <>
      <main className="main-content  mt-0">
        <section>
          <div className="page-header min-vh-75">
            <div className="container">
              <div className="row">
                <div className="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
                  <div className="card card-plain mt-7">
                    <div className="card-header pb-0 text-left bg-transparent">
                      <h1 className="fs-4 font-weight-bolder text-info text-gradient">
                        Trivut Dashboard
                      </h1>
                      <p className="mb-0">
                        Enter your email and password to sign in
                      </p>
                    </div>
                    <div className="card-body">
                      <form role="form">
                        <label>Email</label>
                        <div className="mb-3">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            aria-label="Email"
                            aria-describedby="email-addon"
                          />
                        </div>
                        <label>Password</label>
                        <div className="mb-3">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Password"
                            aria-label="Password"
                            aria-describedby="password-addon"
                          />
                        </div>

                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked
                          />
                          <label
                            class="form-check-label"
                            for="flexSwitchCheckDefault"
                          >
                            Remember Me
                          </label>
                        </div>
                        <div className="text-center">
                          <button
                            type="button"
                            className="btn bg-gradient-info w-100 mt-4 mb-0"
                          >
                            Sign in
                          </button>
                        </div>
                      </form>
                    </div>
                    {/* <div className="card-footer text-center pt-0 px-lg-2 px-1">
                      <p className="mb-4 text-sm mx-auto">
                        Don't have an account?
                        <a
                          href="javascript:;"
                          className="text-info text-gradient font-weight-bold ms-1"
                        >
                          Sign up
                        </a>
                      </p>
                    </div> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="oblique position-absolute top-0 h-100 d-md-block d-none me-n8">
                    <div
                      className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                      style={{ backgroundImage: "url(" + trivutLoginImg + ")" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="footer py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mb-4 mx-auto text-center">
              <Link
                to="/"
                target="_blank"
                className="text-secondary me-xl-5 me-3 mb-sm-0 mb-2"
              >
                About
              </Link>
              <Link
                to="/our-expertise"
                target="_blank"
                className="text-secondary me-xl-5 me-3 mb-sm-0 mb-2"
              >
                Expertise
              </Link>
              <Link
                to="/outsourcing"
                target="_blank"
                className="text-secondary me-xl-5 me-3 mb-sm-0 mb-2"
              >
                Outsourcing
              </Link>
              <a
                href="mailto: sales@trivut.com"
                target="_blank"
                rel="noreferrer"
                className="text-secondary me-xl-5 me-3 mb-sm-0 mb-2"
              >
                Contact
              </a>
            </div>
            <div className="col-lg-8 mx-auto text-center mb-4 mt-2">
              <Link
                to="/"
                target="_blank"
                className="text-secondary me-xl-4 me-4"
              >
                <IconContext.Provider
                  value={{
                    size: "1.4em",
                  }}
                >
                  <AiFillHome />
                </IconContext.Provider>
              </Link>
              <a
                href="https://www.facebook.com/profile.php?id=100086523165246"
                target="_blank"
                rel="noreferrer"
                className="text-secondary me-xl-4 me-4"
              >
                <IconContext.Provider
                  value={{
                    size: "1.3em",
                  }}
                >
                  <FaFacebook />
                </IconContext.Provider>
              </a>
              <a
                href="https://www.linkedin.com/company/trivut-corp/"
                target="_blank"
                rel="noreferrer"
                className="text-secondary me-xl-4 me-4"
              >
                <IconContext.Provider
                  value={{
                    size: "1.4em",
                  }}
                >
                  <AiFillLinkedin />
                </IconContext.Provider>
              </a>
              {/* <a
                href="mailto:sales@trivut.com"
                target="_blank"
                rel="noreferrer"
                className="text-secondary me-xl-4 me-4"
              >
                <IconContext.Provider
                  value={{
                    size: "1.3em",
                  }}
                >
                  <MdEmail />
                </IconContext.Provider>
              </a> */}
            </div>
          </div>
          <div className="row">
            <div className="col-8 mx-auto text-center mt-1">
              <p className="mb-0 text-secondary">
                &copy; Copyright {new Date().getFullYear()}{" "}
                <strong>Trivut Corp.</strong> All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Login;
