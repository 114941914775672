import logo from "../img/logo/logo-small-nobg.webp";

const HeaderLogo = () => {
  return (
    <div className="logo">
      <h1>
        <a className="navbar-brand" href="/">
          <img
            src={logo}
            alt="logo"
            height="50px"
            width="50px"
            className="img-fluid"
          />{" "}
          <span> Trivut Corp.</span>
        </a>
      </h1>
    </div>
  );
};

export default HeaderLogo;
