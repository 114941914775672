import { Link } from "react-router-dom";

import HeaderLogo from "../components/HeaderLogo";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";

import errorImg from "../img/hero-carousel/error.webp";

import { IconContext } from "react-icons";
import { TbFaceIdError } from "react-icons/tb";
import { TbError404 } from "react-icons/tb";
import { TbHome2 } from "react-icons/tb";
import { TbArrowBigLeftLine } from "react-icons/tb";

const ErrorPage = () => {
  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex justify-content-between">
          <HeaderLogo />

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className="nav-link scrollto" href="/">
                  Home
                </a>
              </li>
              {/* <li>
                <a className="nav-link scrollto" href="#contact">
                  Contact Us
                </a>
              </li> */}
            </ul>
          </nav>
        </div>
      </header>

      <section id="hero">
        <div
          id="heroCarousel"
          className="carousel slide carousel-fade"
          data-bs-ride="true"
        >
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="5000">
              <img
                src={errorImg}
                className="d-block h-100"
                alt="404 Page Not Found"
              />
              <div className="carousel-caption carousel-container">
                <div className="container">
                  <h2 className="animate__animated animate__fadeInDown">
                    Page Not Found
                  </h2>
                  <p className="animate__animated animate__fadeInUp">
                    Oops! The page you were looking for couldn't be found.
                    Please check the URL or try navigating back to the previous
                    page.
                  </p>
                  <Link
                    to="/"
                    className="btn-get-started scrollto animate__animated animate__fadeInUp"
                  >
                    Take me home <TbHome2 />
                  </Link>
                  <Link
                    to={-1}
                    className="btn-get-started scrollto animate__animated animate__fadeInUp"
                  >
                    Back
                    <TbArrowBigLeftLine />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <div id="about" className="about-area area-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="section-headline text-center">
                  <h3>
                    <IconContext.Provider
                      value={{
                        size: "7em",
                      }}
                    >
                      <TbFaceIdError />
                      <br />
                    </IconContext.Provider>
                  </h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <h4>Page Not Found</h4>
                <p>
                  It appears you've ventured into uncharted territory. The page
                  you're searching for seems to have vanished into the digital
                  abyss. We apologize for the inconvenience.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Contact Form Section */}
        {/* <ContactForm /> */}
      </main>

      {/* Footer Section */}
      <Footer />
    </>
  );
};

export default ErrorPage;
