import HeaderLogo from "../components/HeaderLogo";
import MarketingSectionBig from "../components/MarketingSectionBig";
import MarketingSectionSmall from "../components/MarketingSectionSmall";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";

import carouselInvestorImg from "../img/hero-carousel/financing-cycle.jpg";

const InvestorRelations = () => {
  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex justify-content-between">
          <HeaderLogo />

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className="nav-link scrollto" href="/">
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about">
                  Be an Angel Investor
                </a>
              </li>
              {/* <li>
                <a className="nav-link scrollto" href="#services">
                  Our Technology Stack
                </a>
              </li> */}
              {/* <li>
                <a className="nav-link scrollto" href="#contact">
                  Investor Form
                </a>
              </li> */}
            </ul>
          </nav>
        </div>
      </header>

      <section id="hero">
        <div
          id="heroCarousel"
          className="carousel slide carousel-fade"
          data-bs-ride="true"
        >
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="5000">
              <img
                src={carouselInvestorImg}
                className="d-block h-100"
                alt="Software Solution Outsourcing"
              />
              <div className="carousel-caption carousel-container">
                <div className="container">
                  <h2 className="animate__animated animate__fadeInDown">
                    Invest in Tomorrow's Innovations
                  </h2>
                  <p className="animate__animated animate__fadeInUp">
                    Explore opportunities to shape the future by becoming an
                    Angel Investor with Trivut Corp. Join our exclusive
                    community, gain insider access to groundbreaking projects,
                    and influence the trajectory of innovative technologies.
                  </p>
                  <a
                    href="/outsourcing"
                    className="btn-get-started scrollto animate__animated animate__fadeInUp"
                  >
                    Investor Form
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <div id="about" className="about-area area-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="section-headline text-center">
                  <h2>Invest in the Future</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                {/* <p>
                  Take your investment journey to new heights with Trivut Corp.!
                  Become an Angel Investor and immerse yourself in a world of
                  innovation and growth. As a valued member of our investor
                  community, you gain unparalleled insights into our
                  cutting-edge projects and emerging technologies. Networking
                  takes center stage, connecting you with like-minded investors
                  and industry pioneers. Your influence matters – contribute to
                  our strategic decisions and be part of the driving force
                  behind Trivut Corp.'s success.
                </p>
                <p>
                  Experience the thrill of being an early supporter, witnessing
                  the birth of groundbreaking solutions before they hit the
                  market. Beyond potential returns, being an Angel Investor at
                  Trivut Corp. means more than an investment – it's a
                  partnership in shaping the future of technology.
                </p>
                <p>
                  Ready to embark on this exciting journey? Join us as an Angel
                  Investor and let's redefine the landscape of innovation
                  together!
                </p> */}
                <p>
                  Embark on an exhilarating and mutually beneficial journey as
                  an angel investor with Trivut Corp, where the convergence of
                  innovation and opportunity beckons. At the heart of our
                  mission is a commitment to redefine the landscape of software
                  development, and your pivotal role as an angel investor can
                  catapult us into unprecedented realms of success.
                </p>
                <p>
                  As a software development trailblazer, Trivut Corp stands on
                  the forefront of the technological revolution, consistently
                  delivering cutting-edge solutions that set industry
                  benchmarks. We have garnered a stellar reputation for creating
                  scalable and innovative software products that not only meet
                  the needs of today but anticipate the demands of tomorrow.
                  Your investment will propel us to new heights, allowing us to
                  further expand our capabilities and solidify our position as
                  industry leaders.
                </p>
                <p>
                  Being an angel investor in Trivut Corp means more than just
                  financial support—it's an invitation to actively participate
                  in the shaping of the future. Your insights, expertise, and
                  guidance will be invaluable as we navigate the ever-evolving
                  tech landscape. We recognize the symbiotic relationship
                  between investors and innovators, and we're committed to
                  fostering an environment where your involvement is not only
                  appreciated but integral to our success.
                </p>
                <p>
                  Moreover, your investment will fuel our research and
                  development initiatives, empowering us to explore emerging
                  technologies, stay ahead of industry trends, and maintain our
                  competitive edge. As an angel investor, you'll witness
                  firsthand the transformative impact of your support as we push
                  the boundaries of what's possible in software development.
                </p>
                <p>
                  In joining forces with Trivut Corp, you're not merely
                  investing in a venture; you're becoming a key player in a
                  narrative of innovation. Your decision to support us carries
                  the promise of not just financial returns but also the
                  satisfaction of contributing to groundbreaking advancements in
                  technology. Seize the opportunity to be part of something
                  extraordinary—invest in Trivut Corp and play a vital role in
                  shaping the future of software development. Together, let's
                  build a legacy of innovation and success.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Marketing Section - Big  */}
        {/* <MarketingSectionBig /> */}

        {/* Marketing Section - Small */}
        {/* <MarketingSectionSmall /> */}

        {/* Contact Form Section */}
        {/* <ContactForm /> */}
      </main>

      {/* Footer Section */}
      <Footer />
    </>
  );
};

export default InvestorRelations;
