import HeaderLogo from "../components/HeaderLogo";
import MarketingSectionBig from "../components/MarketingSectionBig";
import MarketingSectionSmall from "../components/MarketingSectionSmall";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";

import outsourceImg from "../img/hero-carousel/outsource.webp";

const WhyOutsource = () => {
  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex justify-content-between">
          <HeaderLogo />

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className="nav-link scrollto" href="/">
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about">
                  Why Outsource?
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#contact">
                  Connect with Us
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <section id="hero">
        <div
          id="heroCarousel"
          className="carousel slide carousel-fade"
          data-bs-ride="true"
        >
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="5000">
              <img
                src={outsourceImg}
                className="d-block h-100"
                alt="Software Solution Outsourcing"
              />
              <div className="carousel-caption carousel-container">
                <div className="container">
                  <h2 className="animate__animated animate__fadeInDown">
                    Software Solution Outsourcing
                  </h2>
                  <p className="animate__animated animate__fadeInUp">
                    Outsourcing your software development needs to a specialized
                    team can save your business time and money.
                  </p>
                  <a
                    href="#contact"
                    className="btn-get-started scrollto animate__animated animate__fadeInUp"
                  >
                    Connect with Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <div id="about" className="about-area area-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="section-headline text-center">
                  <h2>Why Outsource?</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <p>
                  In today's fast-paced business world, software development is
                  becoming increasingly important. However, developing software
                  in-house can be a time-consuming and expensive process. This
                  is where software development outsourcing comes in.
                  Outsourcing your software development needs can be a smart
                  move for your business, as it allows you to focus on your core
                  competencies while benefiting from the expertise of a
                  specialized team.
                </p>
                <p>
                  When it comes to software development, security is of utmost
                  importance. The security of your software and data is crucial
                  for protecting your business against cyber attacks and data
                  breaches. That's why it's essential to work with an
                  outsourcing partner that takes security seriously. At Trivut,
                  we understand the importance of security, and we make it a top
                  priority in everything we do. From secure code development to
                  secure infrastructure, we ensure that your software is built
                  with security in mind at every step of the process.
                </p>
                <p>
                  Cloud-based solutions are becoming increasingly popular among
                  businesses of all sizes. Cloud-based software offers many
                  benefits, including scalability, flexibility, and
                  cost-effectiveness. With cloud-based software, you can easily
                  scale your software to meet changing business needs, and you
                  only pay for what you use. Our software development
                  outsourcing services include cloud-based solutions, so you can
                  take advantage of these benefits without having to worry about
                  the technical details.
                </p>
                <p>
                  Outsourcing your software development needs to a specialized
                  team can save your business time and money. By outsourcing,
                  you can tap into the expertise of a team of developers who
                  have experience with a wide range of technologies and
                  platforms. This means that you don't have to invest time and
                  resources into training your in-house team on new
                  technologies. In addition, outsourcing allows you to focus on
                  your core competencies, such as product development or
                  customer service, while leaving the software development to
                  the experts.
                </p>
                <p>
                  At Trivut, we specialize in software development outsourcing
                  services, and we have a team of experienced developers who can
                  help you bring your software ideas to life. Whether you need a
                  web application, mobile app, or custom software solution, we
                  have the skills and expertise to deliver high-quality software
                  that meets your business needs. Our team of developers has
                  experience working with a wide range of technologies,
                  including Amazon AWS, and we can help you take advantage of
                  the many benefits of cloud-based software solutions.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Marketing Section - Big  */}
        <MarketingSectionBig />

        {/* Marketing Section - Small */}
        <MarketingSectionSmall />

        {/* Contact Form Section */}
        <ContactForm />
      </main>

      {/* Footer Section */}
      <Footer />
    </>
  );
};

export default WhyOutsource;
