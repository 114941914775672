export const NurseOnDemandData = [
  {
    id: 1,
    year: "Oct '23",
    followersGain: 0,
    likesGain: 0,
    totalFollowers: 0,
    totalLikes: 0,
  },
  {
    id: 2,
    year: "Nov '23",
    followersGain: 24,
    likesGain: 10,
    totalFollowers: 24,
    totalLikes: 10,
  },
  {
    id: 3,
    year: "Dec '23",
    followersGain: 16,
    totalFollowers: 40,
    likesGain: 3,
    totalLikes: 13,
  },
  {
    id: 4,
    year: "Jan '24",
    followersGain: 15,
    totalFollowers: 55,
    likesGain: 4,
    totalLikes: 17,
  },
  {
    id: 5,
    year: "Feb '24",
    followersGain: 2,
    totalFollowers: 57,
    likesGain: 0,
    totalLikes: 17,
  },
  {
    id: 6,
    year: "Mar '24",
    followersGain: 1,
    totalFollowers: 58,
    likesGain: 0,
    totalLikes: 17,
  },
  {
    id: 7,
    year: "Apr '24",
    followersGain: 6,
    totalFollowers: 64,
    likesGain: 2,
    totalLikes: 19,
  },
  {
    id: 8,
    year: "May '24",
    followersGain: 74,
    totalFollowers: 138,
    likesGain: 22,
    totalLikes: 41,
  },
  {
    id: 9,
    year: "Jun '24",
    followersGain: 4,
    totalFollowers: 142,
    likesGain: 0,
    totalLikes: 41,
  },
  {
    id: 10,
    year: "Jul '24",
    followersGain: 2,
    totalFollowers: 144,
    likesGain: 0,
    totalLikes: 41,
  },
  {
    id: 11,
    year: "Aug '24",
    followersGain: 1,
    totalFollowers: 144,
    likesGain: 2,
    totalLikes: 41,
  },
  {
    id: 12,
    year: "Sept '24",
    followersGain: 1,
    totalFollowers: 143,
    likesGain: 0,
    totalLikes: 40,
  },
  {
    id: 13,
    year: "Oct '24",
    followersGain: 2,
    totalFollowers: 145,
    likesGain: 1,
    totalLikes: 41,
  },
  {
    id: 14,
    year: "Nov '24",
    followersGain: 2,
    totalFollowers: 145,
    likesGain: 1,
    totalLikes: 41,
  },
];
