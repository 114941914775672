import { Link } from "react-router-dom";

import logoImg from "../assets/img/logo/logo-small-nobg.png";

import {
  FcReadingEbook,
  FcPieChart,
  FcMultipleDevices,
  FcSurvey,
  FcCalendar,
  FcAreaChart,
  FcOvertime,
  FcTodoList,
} from "react-icons/fc";

const SideNav = () => {
  return (
    <aside
      className="sidenav sidenav navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 d-none d-xl-block d-xxl-none"
      id="sidenav-main"
    >
      <div className="sidenav-header">
        <i
          className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
          aria-hidden="true"
          id="iconSidenav"
        ></i>
        <Link to="/dashboard" className="navbar-brand m-0">
          <img src={logoImg} className="navbar-brand-img h-100" alt="logo" />
          <span className="ms-1 font-weight-bolder text-merriweather">
            Trivut Dashboard
          </span>
        </Link>
      </div>
      <hr className="horizontal dark mt-0" />
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link to="/dashboard" className="nav-link-dashboard active">
            <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center text-black me-2 d-flex align-items-center justify-content-center">
              <FcPieChart />
            </div>
            <span className="nav-link-text ms-1">Dashboard</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/dashboard/projects" className="nav-link-dashboard">
            <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
              <FcMultipleDevices />
            </div>
            <span className="nav-link-text ms-1">Projects</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/dashboard/notes" className="nav-link-dashboard">
            <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
              <FcSurvey />
            </div>
            <span className="nav-link-text ms-1">Notes</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/dashboard/calendar" className="nav-link-dashboard">
            <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
              <FcCalendar />
            </div>
            <span className="nav-link-text ms-1">Calendar</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/dashboard/reports-and-analytics"
            className="nav-link-dashboard"
          >
            <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
              <FcAreaChart />
            </div>
            <span className="nav-link-text ms-1">Reports</span>
          </Link>
        </li>
        <li className="nav-item mt-3">
          <h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">
            DevJedi Training
          </h6>
        </li>
        <li className="nav-item">
          <Link
            to="/dashboard/training/attendance"
            className="nav-link-dashboard"
          >
            <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
              <FcOvertime />
            </div>
            <span className="nav-link-text ms-1">Attendance</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/dashboard/training/progress-tracker"
            className="nav-link-dashboard"
          >
            <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
              <FcTodoList />
            </div>
            <span className="nav-link-text ms-1">Progress Tracker</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/dashboard/training/projects"
            className="nav-link-dashboard"
          >
            <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
              <FcReadingEbook />
            </div>
            <span className="nav-link-text ms-1">DevJedi Projects</span>
          </Link>
        </li>
      </ul>
    </aside>
  );
};

export default SideNav;
