import HeaderLogo from "../components/HeaderLogo";
import MarketingSectionBig from "../components/MarketingSectionBig";
import MarketingSectionSmall from "../components/MarketingSectionSmall";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";

import carouselImg1 from "../img/hero-carousel/home-outsource.webp";
import carouselImg2 from "../img/hero-carousel/home-solutions.webp";
import carouselImg3 from "../img/hero-carousel/home-cost-effective.webp";
import carouselImg4 from "../img/hero-carousel/home-cloud-based.webp";
import carouselImg5 from "../img/hero-carousel/home-scalability.webp";
import carouselImg6 from "../img/hero-carousel/togo-tokenized-healthcare.webp";
import trivutImg1 from "../img/about/1.webp";
import rickyPhoto from "../img/team/RickyPetilla.webp";
import melvinPhoto from "../img/team/MelvinChua.webp";
import peterPhoto from "../img/team/PeterTrurnit.webp";
import vincentPhoto from "../img/team/VincentGaspay.webp";
import jasonPhoto from "../img/team/JasonOrdiz.webp";
import athenaPhoto from "../img/team/AthenaLlamera.webp";
import keithPhoto from "../img/team/KeithSinining.webp";
import patPhoto from "../img/team/PatGatchalian.webp";
import gracePhoto from "../img/team/JoAnnGraceMilitanteYu.webp";
import chrisPhoto from "../img/team/ChrisMelo.webp";
import jaemilyPhoto from "../img/team/JaemilyCadimas.webp";

import nurseOnDemandLogo from "../img/about/NOD-Logo.jpg";
import togoHealthcareToken from "../img/about/togo_healthcare_token.png";

import { IconContext } from "react-icons";
import {
  FcHome,
  FcDocument,
  FcFinePrint,
  FcOpenedFolder,
  FcShop,
  FcIdea,
  FcElectricalSensor,
  FcOrganization,
} from "react-icons/fc";

import {
  BsFacebook,
  BsLinkedin,
  BsChevronLeft,
  BsChevronRight,
} from "react-icons/bs";

import { FaHandshake } from "react-icons/fa";

const HomePage = () => {
  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex justify-content-between">
          <HeaderLogo />

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className="nav-link scrollto" href="#about">
                  About
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#services">
                  <span className="d-none d-lg-block">Our Services</span>
                  <span className="d-lg-none">Services</span>
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#applications">
                  <span className="d-none d-lg-block">Our Applications</span>
                  <span className="d-lg-none">Apps</span>
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#projects">
                  Our Projects
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#team">
                  Team
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#contact">
                  Contact Us
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <section id="hero">
        <div
          id="heroCarousel"
          className="carousel slide carousel-fade"
          data-bs-ride="true"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#heroCarousel"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#heroCarousel"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#heroCarousel"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#heroCarousel"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-bs-target="#heroCarousel"
              data-bs-slide-to="4"
              aria-label="Slide 5"
            ></button>
            {/* <button
              type="button"
              data-bs-target="#heroCarousel"
              data-bs-slide-to="5"
              aria-label="Slide 6"
            ></button> */}
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="7000">
              <img
                src={carouselImg1}
                className="d-block h-100"
                alt="Software Solution Outsourcing"
              />
              <div className="carousel-caption carousel-container">
                <div className="container">
                  <h2 className="animate__animated animate__fadeInDown">
                    Software Solution Outsourcing
                  </h2>
                  <p className="animate__animated animate__fadeInUp">
                    Scale your business with ease and benefit from our flexible
                    approach to outsourcing, tailored to meet your specific
                    needs
                  </p>
                  <a
                    href="/outsourcing"
                    className="btn-get-started scrollto animate__animated animate__fadeInUp"
                  >
                    Why Outsource?
                  </a>
                </div>
              </div>
            </div>
            <div className="carousel-item" data-bs-interval="7000">
              <img
                src={carouselImg2}
                className="d-block h-100"
                alt="Crafting Cutting-Edge Solutions"
              />
              <div className="carousel-caption carousel-container">
                <div className="container">
                  <h2 className="animate__animated animate__fadeInDown">
                    Crafting Cutting-Edge Solutions
                  </h2>
                  <p className="animate__animated animate__fadeInUp">
                    We build innovative software solutions that propel your
                    business forward and bring your ideas to life
                  </p>
                  <a
                    href="/our-expertise"
                    className="btn-get-started scrollto animate__animated animate__fadeInUp"
                  >
                    Our Solutions
                  </a>
                </div>
              </div>
            </div>
            <div className="carousel-item" data-bs-interval="7000">
              <img
                src={carouselImg3}
                className="d-block h-100"
                alt="Cost-effective Outsourcing"
              />
              <div className="carousel-caption carousel-container">
                <div className="container">
                  <h2 className="animate__animated animate__fadeInDown">
                    Cost-effective Outsourcing
                  </h2>
                  <p className="animate__animated animate__fadeInUp">
                    Affordable solution that doesn't compromise on quality or
                    performance, ensuring cost-effectiveness for your business
                  </p>
                  <a
                    href="/outsourcing"
                    className="btn-get-started scrollto animate__animated animate__fadeInUp"
                  >
                    Outsourcing Benefits
                  </a>
                </div>
              </div>
            </div>
            <div className="carousel-item" data-bs-interval="7000">
              <img
                src={carouselImg4}
                className="d-block h-100"
                alt="Cloud-based Software Solutions"
              />
              <div className="carousel-caption carousel-container">
                <div className="container">
                  <h2 className="animate__animated animate__fadeInDown">
                    Cloud-based Software Solutions
                  </h2>
                  <p className="animate__animated animate__fadeInUp">
                    Our cloud-based applications offer benefits in scalability,
                    flexibility, and cost-effectiveness
                  </p>
                  <a
                    href="/our-expertise"
                    className="btn-get-started scrollto animate__animated animate__fadeInUp"
                  >
                    Our Expertise
                  </a>
                </div>
              </div>
            </div>
            <div className="carousel-item" data-bs-interval="7000">
              <img
                src={carouselImg5}
                className="d-block h-100"
                alt="Flexibility and Scalability"
              />
              <div className="carousel-caption carousel-container">
                <div className="container">
                  <h2 className="animate__animated animate__fadeInDown">
                    Flexibility and Scalability
                  </h2>
                  <p className="animate__animated animate__fadeInUp">
                    Experience software solutions that can grow and adapt with
                    your business needs effortlessly
                  </p>
                  <a
                    href="#contact"
                    className="btn-get-started scrollto animate__animated animate__fadeInUp"
                  >
                    Connect with Us
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="carousel-item" data-bs-interval="7000">
              <img
                src={carouselImg6}
                className="d-block h-100"
                alt="TOGO Tokenized Healthcare"
              />
              <div className="carousel-caption carousel-container">
                <div className="container">
                  <h2 className="animate__animated animate__fadeInDown">
                    TOGO Tokenized Healthcare Currency
                  </h2>
                  <p className="animate__animated animate__fadeInUp">
                    TOGO is a groundbreaking digital currency designed to
                    operate on an advanced healthcare platform, transforming how
                    medical services are accessed, paid for, and delivered
                    globally.
                  </p>
                  <a
                    href="/togo-token"
                    className="btn-get-started scrollto animate__animated animate__fadeInUp"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div> */}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#heroCarousel"
            data-bs-slide="prev"
            aria-label="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true">
              <BsChevronLeft />
            </span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#heroCarousel"
            data-bs-slide="next"
            aria-label="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true">
              <BsChevronRight />
            </span>
          </button>
        </div>
      </section>
      <main id="main">
        <div id="about" className="about-area area-padding">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-headline text-center">
                  <h2>About Trivut Corp.</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="d-none d-sm-block d-sm-none d-md-block col-lg-6 col-md-12 mb-2">
                <div className="well-left">
                  <div className="single-well">
                    <img
                      className="img-fluid"
                      width="auto"
                      height="auto"
                      src={trivutImg1}
                      alt="office"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="well-middle">
                  <div className="single-well">
                    <h3 className="sec-head">
                      Digital Transformation and Innovation
                    </h3>
                    <p>
                      Trivut Corporation envisions transformation,
                      digitalization, and automation of processes into
                      application software that are vital in our evolving world
                      for private, commercial, and government entities.
                    </p>
                    <p>
                      We develop innovative application software and
                      technologies to suit your needs in order to harness the
                      power and advantages of our interconnected world. Our end
                      products transform and digitize data, automating your
                      processes and services using sophisticated machine
                      learning algorithms and artificial intelligence.
                    </p>
                    <p>
                      We diversify application software, research artificial
                      intelligent technologies, and prototype software products
                      ranging from finance, supply chain, human resource,
                      e-commerce, healthcare, public governance, and compliance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div id="services" className="services-area area-padding">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-headline services-head text-center">
                  <h2>Our Services</h2>
                </div>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div className="about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <a className="services-icon" href="#">
                        <i className="bi bi-briefcase"></i>
                      </a>
                      <h4>Expert Coder</h4>
                      <p>
                        will have to make sure the prototype looks finished by
                        inserting text or photo.make sure the prototype looks
                        finished by.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div className="about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <a className="services-icon" href="#">
                        <i className="bi bi-card-checklist"></i>
                      </a>
                      <h4>Creative Designer</h4>
                      <p>
                        will have to make sure the prototype looks finished by
                        inserting text or photo.make sure the prototype looks
                        finished by.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div className=" about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <a className="services-icon" href="#">
                        <i className="bi bi-bar-chart"></i>
                      </a>
                      <h4>Wordpress Developer</h4>
                      <p>
                        will have to make sure the prototype looks finished by
                        inserting text or photo.make sure the prototype looks
                        finished by.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div className=" about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <a className="services-icon" href="#">
                        <i className="bi bi-binoculars"></i>
                      </a>
                      <h4>Social Marketer </h4>
                      <p>
                        will have to make sure the prototype looks finished by
                        inserting text or photo.make sure the prototype looks
                        finished by.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div className=" about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <a className="services-icon" href="#">
                        <i className="bi bi-brightness-high"></i>
                      </a>
                      <h4>Seo Expart</h4>
                      <p>
                        will have to make sure the prototype looks finished by
                        inserting text or photo.make sure the prototype looks
                        finished by.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div className=" about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <a className="services-icon" href="#">
                        <i className="bi bi-calendar4-week"></i>
                      </a>
                      <h4>24/7 Support</h4>
                      <p>
                        will have to make sure the prototype looks finished by
                        inserting text or photo.make sure the prototype looks
                        finished by.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* Our Services Section - Start */}
        <div id="services" className="services-area area-padding">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-headline text-center">
                  <h2>Our Services</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p>
                  In the modern business landscape, cloud-based web applications
                  are a critical component of success. Trivut Corp. has
                  extensive experience in building custom cloud-based web
                  applications that enable businesses to thrive in the digital
                  age. Using the latest technologies and industry best
                  practices, our team of developers delivers robust, secure, and
                  scalable applications that meet your unique business needs.
                </p>
                <p>
                  We specialize in building cloud-based web applications using
                  leading platforms such as Amazon Web Services (AWS). Our
                  developers are experts in the latest front-end and back-end
                  web development frameworks, allowing us to create
                  high-performance web applications that are intuitive and easy
                  to use. Whether you need a simple web application or a complex
                  enterprise-level solution, we have the expertise to deliver
                  the right solution for your business.
                </p>
                <p>
                  With our software development outsourcing services, you can
                  save time and money by leveraging our expertise and resources.
                  We work closely with you to understand your business needs and
                  provide you with a tailored solution that meets your specific
                  requirements. Our focus on cloud-based web applications
                  enables us to deliver high-quality solutions that are secure,
                  scalable, and cost-effective. So why wait?{" "}
                  <a href="#contact">Contact us</a> today to learn more about
                  our services and how we can help you achieve your business
                  goals with cloud-based web applications.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Our Services Section - End */}

        {/* Our Applications Section - Start */}
        <div id="applications" className="applications-area area-padding">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-headline services-head text-center">
                  <h2>Trivut Applications</h2>
                </div>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <div className="about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <a
                        className="services-icon"
                        href="/document-tracking-system"
                        rel="noreferrer"
                        target="_blank"
                        aria-label="Document Tracking System Page"
                      >
                        <IconContext.Provider value={{ size: "1.4em" }}>
                          <FcOpenedFolder />
                        </IconContext.Provider>
                      </a>
                      <h3>Document Tracking System (DTS)</h3>
                      <p>
                        A cloud-based application that facilitates the tracking
                        and status of various official documents of your
                        organization as they move from one department, division,
                        unit, or section to another resulting in increased
                        efficiency of official transactions and prevent loss of
                        documents.{" "}
                        <a
                          href="/document-tracking-system"
                          rel="noreferrer"
                          target="_blank"
                          className=""
                        >
                          Read more...
                        </a>
                        <br />
                        <a
                          href="https://dts.trivut.app"
                          rel="noreferrer"
                          target="_blank"
                          className="p-2"
                        >
                          Try a demo
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <div className="about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <div className="services-icon">
                        <IconContext.Provider value={{ size: "1.4em" }}>
                          <FcDocument />
                        </IconContext.Provider>
                      </div>
                      <h3>
                        Business Permit and Licensing Management System (BPLMS)
                      </h3>
                      <p>
                        Transform legacy business permit and licensing systems
                        to a cloud-based application that digitize and automate
                        processing of business permits and licenses to provide a
                        systematic and efficient delivery of services to
                        business entities and individuals in the LGUs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <div className=" about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <div className="services-icon">
                        <IconContext.Provider value={{ size: "1.4em" }}>
                          <FcElectricalSensor />
                        </IconContext.Provider>
                      </div>
                      <h3>Trivut Health Management System (THMS)</h3>
                      <p>
                        The Trivut Health Management System (THMS) is a
                        healthcare delivery infrastructure that transforms an
                        outdated centralized, hospital-centered delivery system
                        into a patient-centered global healthcare system. Our
                        system enhances business operations with smart digitized
                        tools for quality patient care.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <div className=" about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <div className="services-icon">
                        <IconContext.Provider value={{ size: "1.4em" }}>
                          <FcFinePrint />
                        </IconContext.Provider>
                      </div>
                      <h3>
                        Legislative Ordinance and Indexing and Tracking System
                        (LOITS)
                      </h3>
                      <p>
                        A Legislative Ordinance and Indexing and Tracking System
                        is our software solution designed to manage the
                        legislative process and document tracking within
                        government organizations. Our system provides a
                        centralized platform to store, index, and track all
                        legislative documents and information, including bills,
                        laws, regulations, and other legal documents.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <div className=" about-move">
                  <div className="services-details">
                    <dv className="single-services">
                      <div className="services-icon">
                        <IconContext.Provider value={{ size: "1.4em" }}>
                          <FcHome />
                        </IconContext.Provider>
                      </div>
                      <h3>
                        Realty Tax Management System
                        <br />
                        (RTMS)
                      </h3>
                      <p>
                        Simplify your realty tax process with our realty tax
                        management system! Our platform streamlines the payment
                        process, reduces tax liability, and provides real-time
                        data and insights to help you stay on top of your realty
                        taxes. Whether you're a property owner, management
                        company, or local government, our solution will help you
                        stay organized and informed.
                      </p>
                    </dv>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <div className=" about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <div className="services-icon">
                        <IconContext.Provider value={{ size: "1.4em" }}>
                          <FcOrganization />
                        </IconContext.Provider>
                      </div>
                      <h3>
                        Generalized Government Accounting System
                        <br /> (GGAS)
                      </h3>
                      <p>
                        Our platform offers a centralized and secure solution
                        for managing financial transactions, budgeting,
                        reporting, and auditing. With real-time data insights,
                        automated processes, and flexible reporting options, our
                        solution is designed to meet the unique needs of
                        government organizations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Our Applications Section - End */}

        {/* Web Applications Section - Start */}
        <div id="projects" className="projects-area area-padding">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-headline services-head text-center">
                  <h2>Our Projects</h2>
                </div>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <div className="services-icon">
                        <IconContext.Provider value={{ size: "1.4em" }}>
                          <FcShop />
                        </IconContext.Provider>
                      </div>
                      <h3>
                        Trivut Marketplace
                        <br />
                        <br />
                      </h3>
                      <p>
                        Discover the power of our online selling platform and
                        unlock your business's full potential. Our platform
                        offers a seamless shopping experience for your customers
                        with secure payment options and fast, reliable shipping.
                        With powerful tools for inventory management and
                        marketing, you can streamline your operations and grow
                        your sales.
                        <a
                          href="https://marketplace.trivut.com/"
                          rel="noreferrer"
                          target="_blank"
                          className="p-2"
                        >
                          Visit site
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className=" about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <div className="services-icon">
                        <IconContext.Provider value={{ size: "1.4em" }}>
                          <FcIdea />
                        </IconContext.Provider>
                      </div>
                      <h3>
                        Trivut Ideas
                        <br />
                        <br />
                      </h3>
                      <p>
                        Fuel your creativity and bring your ideas to life with
                        our online idea funding platform. Our platform connects
                        you with a global network of investors who are
                        passionate about supporting innovative ideas. With
                        easy-to-use tools for crowdfunding, project management,
                        and collaboration, you can turn your dreams into
                        reality.
                        <a
                          href="https://ideas.trivut.com/"
                          rel="noreferrer"
                          target="_blank"
                          className="p-2"
                        >
                          Visit site
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <div className="services-icon">
                        <img
                          src={nurseOnDemandLogo}
                          alt="Nurse On-Demand logo"
                          style={{ height: "60px", width: "60px" }}
                        />
                      </div>
                      <h3>
                        Trivut's Nurse On-Demand
                        <br />
                        <br />
                      </h3>
                      <p>
                        Embark on a new era of healthcare convenience with
                        Trivut's Nurse On-Demand application. Our innovative
                        platform puts the power of personalized healthcare in
                        your hands, allowing you to effortlessly book services
                        from licensed nurses for basic procedures, all within
                        the comfort of your home.
                      </p>
                      <p>
                        Trivut's Nurse On-Demand App empowers you to schedule
                        licensed nurses for a range of essential services, from
                        administering vaccinations to conducting health
                        check-ups, right at your doorstep. It's healthcare that
                        revolves around your schedule, making wellness
                        accessible and hassle-free.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <div className="services-icon">
                        <IconContext.Provider
                          value={{ color: "#0A6BB4", size: "1.7em" }}
                        >
                          <FaHandshake />
                        </IconContext.Provider>
                      </div>
                      <h3>
                        Trivut's Service On-Demand
                        <br />
                        <br />
                      </h3>
                      {/* <p>
                        Ditch the endless calls and say goodbye to scheduling
                        headaches! Introducing the all-in-one solution for your
                        home service needs: Trivut's Service On-Demand. With
                        just a few taps on your phone, you can book a variety of
                        trusted professionals to come directly to your doorstep,
                        from cleaning and handyman services to appliance repair
                        and pet care.
                      </p> */}
                      <p>
                        Ditch endless calls and scheduling headaches! Meet
                        Trivut's Service On-Demand: your all-in-one home service
                        solution. Book trusted professionals—from cleaners to
                        handymen, appliance repair to pet care—with just a few
                        taps.
                      </p>
                      <p>
                        Trivut's Service On-Demand makes life easier by
                        connecting you with qualified and verified service
                        providers. View profiles, compare prices, and choose the
                        perfect professional for your job. Enjoy the convenience
                        of online booking and secure payment, all within the
                        app. Get back to what matters most while Service
                        On-Demand takes care of the rest!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <div className="services-icon">
                        <a
                          href="/togo-token"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Togo Token Page"
                        >
                          <img
                            src={togoHealthcareToken}
                            alt="TOGO Tokenized Healthcare logo"
                            style={{ height: "60px", width: "60px" }}
                          />
                        </a>
                      </div>
                      <h3>
                        <a
                          href="/togo-token"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Togo Token Page"
                        >
                          TOGO Tokenized Healthcare
                        </a>
                        <br />
                        <br />
                      </h3>
                      <p>
                        In the ever-evolving landscape of healthcare and
                        technology, Trivut Healthcare System proudly presents{" "}
                        <a
                          href="/togo-token"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Togo Token Page"
                        >
                          TOGO
                        </a>{" "}
                        – the cutting-edge, blockchain-based tokenized
                        healthcare currency designed to transform your
                        healthcare experience.{" "}
                        <a
                          href="/togo-token"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Togo Token Page"
                        >
                          TOGO
                        </a>{" "}
                        tokenized currency is not just a means of payment; it's
                        a gateway to a more efficient, transparent, and
                        accessible healthcare journey.
                      </p>
                      <p>
                        <a
                          href="/togo-token"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Togo Token Page"
                        >
                          TOGO
                        </a>{" "}
                        is more than just a currency, it's a movement. It's
                        about putting you at the center of your healthcare
                        journey, rewarding you for making healthy choices, and
                        building a more accessible and transparent healthcare
                        system for everyone.
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="row text-center">
                <div className="col-md-3 col-sm-3 d-none d-sm-block"></div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <div className="about-move">
                    <div className="services-details">
                      <div className="single-services">
                        <div className="services-icon">
                          <IconContext.Provider
                            value={{ color: "#0A6BB4", size: "1.7em" }}
                          >
                            <FaHandshake />
                          </IconContext.Provider>
                        </div>
                        <h3>
                          Trivut's Service On-Demand
                          <br />
                          <br />
                        </h3>
                        <p>
                          Ditch the endless calls and say goodbye to scheduling
                          headaches! Introducing the all-in-one solution for
                          your home service needs: Trivut's Service On-Demand.
                          With just a few taps on your phone, you can book a
                          variety of trusted professionals to come directly to
                          your doorstep, from cleaning and handyman services to
                          appliance repair and pet care.
                        </p>
                        <p>
                          Trivut's Service On-Demand makes life easier by
                          connecting you with qualified and verified service
                          providers. View profiles, compare prices, and choose
                          the perfect professional for your job. Enjoy the
                          convenience of online booking and secure payment, all
                          within the app. Get back to what matters most while
                          Service On-Demand takes care of the rest!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-3 d-none d-sm-block"></div>
              </div> */}
            </div>
          </div>
        </div>
        {/* Our Applications Section - End */}

        {/* Trivut Team Section - Start */}
        <div id="team" className="our-team-area area-padding">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-headline text-center">
                  <h2>The Trivut Team</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div className="single-team-member">
                  <div className="team-img">
                    <a href="#ricky">
                      <img src={rickyPhoto} alt="Ricky Petilla" />
                    </a>
                    <div className="team-social-icon text-center">
                      <ul>
                        <li>
                          <a
                            href="https://web.facebook.com/profile.php?id=100086523165246"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Facebook"
                          >
                            <IconContext.Provider value={{ size: "14px" }}>
                              <BsFacebook />
                            </IconContext.Provider>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="LinkedIn"
                          >
                            <IconContext.Provider value={{ size: "14px" }}>
                              <BsLinkedin />
                            </IconContext.Provider>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="team-content text-center">
                    <h3>
                      Ricky C. Petilla
                      <br className="d-none d-lg-block d-xl-none" />
                    </h3>
                    <p className="d-none d-md-block d-lg-none">&nbsp;</p>
                    <p>Co-Founder | CEO | Micro Apps Architect</p>
                    <p className="d-none d-md-block d-lg-none">&nbsp;</p>
                    <p className="d-none d-lg-block d-xxl-none">&nbsp;</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="single-team-member">
                  <div className="team-img">
                    <a href="#melvin">
                      <img src={melvinPhoto} alt="Melvin Chua" />
                    </a>
                    <div className="team-social-icon text-center">
                      <ul>
                        <li>
                          <a
                            href="https://web.facebook.com/profile.php?id=100086523165246"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Facebook"
                          >
                            <IconContext.Provider value={{ size: "14px" }}>
                              <BsFacebook />
                            </IconContext.Provider>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/in/chuamelvin/"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="LinkedIn"
                          >
                            <IconContext.Provider value={{ size: "14px" }}>
                              <BsLinkedin />
                            </IconContext.Provider>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="team-content text-center">
                    <h3>
                      Melvin Chua
                      <br className="d-none d-lg-block d-xl-none" />
                    </h3>
                    <p className="d-none d-md-block d-lg-none">&nbsp;</p>
                    <p>Co-Founder | CTO | DevSecOps Engineer</p>
                    <p className="d-none d-md-block d-lg-none">&nbsp;</p>
                    <p className="d-none d-lg-block d-xxl-none">&nbsp;</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="single-team-member">
                  <div className="team-img">
                    <a href="#peter">
                      <img src={peterPhoto} alt="Peter Trurnit" />
                    </a>
                    <div className="team-social-icon text-center">
                      <ul>
                        <li>
                          <a
                            href="https://web.facebook.com/profile.php?id=100086523165246"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Facebook"
                          >
                            <IconContext.Provider value={{ size: "14px" }}>
                              <BsFacebook />
                            </IconContext.Provider>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/in/peter-trurnit-85a6781b2/"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="LinkedIn"
                          >
                            <IconContext.Provider value={{ size: "14px" }}>
                              <BsLinkedin />
                            </IconContext.Provider>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="team-content text-center">
                    <h3>
                      Peter Trurnit
                      <br className="d-none d-lg-block d-xl-none" />
                    </h3>
                    <p className="d-none d-md-block d-lg-none">&nbsp;</p>
                    <p className="d-none d-sm-block d-md-none">&nbsp;</p>
                    <p>Co-Founder | Chief Marketing Technologist</p>
                    <p className="d-none d-lg-block d-xxl-none">&nbsp;</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="single-team-member">
                  <div className="team-img">
                    <a href="#vincent">
                      <img src={vincentPhoto} alt="Vincent John Gasapy" />
                    </a>
                    <div className="team-social-icon text-center">
                      <ul>
                        <li>
                          <a
                            href="https://web.facebook.com/profile.php?id=100086523165246"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Facebook"
                          >
                            <IconContext.Provider value={{ size: "14px" }}>
                              <BsFacebook />
                            </IconContext.Provider>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="LinkedIn"
                          >
                            <IconContext.Provider value={{ size: "14px" }}>
                              <BsLinkedin />
                            </IconContext.Provider>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="team-content text-center">
                    <h3>Vincent John Gaspay</h3>
                    <p>
                      Co-Founder | Chief Products Engineer | DevJedis Training
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-3 col-sm-6">
                <div className="single-team-member">
                  <div className="team-img">
                    <a href="#jason">
                      <img src={jasonPhoto} alt="Jason Gonzales Ordiz" />
                    </a>
                    <div className="team-social-icon text-center">
                      <ul>
                        <li>
                          <a
                            href="https://web.facebook.com/profile.php?id=100086523165246"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Facebook"
                          >
                            <IconContext.Provider value={{ size: "14px" }}>
                              <BsFacebook />
                            </IconContext.Provider>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/in/jason-ordiz-610b912/"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="LinkedIn"
                          >
                            <IconContext.Provider value={{ size: "14px" }}>
                              <BsLinkedin />
                            </IconContext.Provider>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="team-content text-center">
                    <h3>Jason Gonzales Ordiz</h3>
                    <div className="d-none d-lg-block d-xxl-none">&nbsp;</div>
                    <p>Chief Financial Officer | Innovation Strategist</p>
                    <p className="d-none d-lg-block d-xl-none">&nbsp;</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="single-team-member">
                  <div className="team-img">
                    <a href="#athena">
                      <img src={athenaPhoto} alt="Athena J. Llamera" />
                    </a>
                    <div className="team-social-icon text-center">
                      <ul>
                        <li>
                          <a
                            href="https://web.facebook.com/profile.php?id=100086523165246"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Facebook"
                          >
                            <IconContext.Provider value={{ size: "14px" }}>
                              <BsFacebook />
                            </IconContext.Provider>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="LinkedIn"
                          >
                            <IconContext.Provider value={{ size: "14px" }}>
                              <BsLinkedin />
                            </IconContext.Provider>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="team-content text-center">
                    <h3>Athena J. Llamera</h3>
                    <p className="d-none d-xl-block d-xxl-none">&nbsp;</p>
                    <p>
                      Chief Corporate Communications | Domain Specialist -
                      Government Systems
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="single-team-member">
                  <div className="team-img">
                    <a href="#keith">
                      <img src={keithPhoto} alt="Keith Son Leovel Sinining" />
                    </a>
                    <div className="team-social-icon text-center">
                      <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Facebook"
                          >
                            <IconContext.Provider value={{ size: "14px" }}>
                              <BsFacebook />
                            </IconContext.Provider>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="LinkedIn"
                          >
                            <IconContext.Provider value={{ size: "14px" }}>
                              <BsLinkedin />
                            </IconContext.Provider>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="team-content text-center">
                    <h3>Keith Son Leovel Sinining</h3>
                    <p className="d-none d-lg-block d-xl-none">&nbsp;</p>
                    <p>Software Engineer | Domain Specialist - Healthcare</p>
                    <br className="d-none d-lg-block d-xxl-none" />
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="single-team-member">
                  <div className="team-img">
                    <a href="#pat">
                      <img src={patPhoto} alt="Patricia Marie L. Gatchalian" />
                    </a>
                    <div className="team-social-icon text-center">
                      <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Facebook"
                          >
                            <IconContext.Provider value={{ size: "14px" }}>
                              <BsFacebook />
                            </IconContext.Provider>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="LinkedIn"
                          >
                            <IconContext.Provider value={{ size: "14px" }}>
                              <BsLinkedin />
                            </IconContext.Provider>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="team-content text-center">
                    <h3>Patricia Marie L. Gatchalian</h3>
                    <p className="d-none d-lg-block d-xl-none">&nbsp;</p>
                    <p>Software Engineer | Domain Specialist - Healthcare</p>
                    <br className="d-none d-lg-block d-xxl-none" />
                  </div>
                </div>
              </div>

              {/* new section  */}
              <div className="row mt-4">
                <div className="col-md-3 col-sm-6">
                  <div className="single-team-member">
                    <div className="team-img">
                      <a href="#grace">
                        <img src={gracePhoto} alt="Jo Ann Grace Militante-Yu" />
                      </a>
                      <div className="team-social-icon text-center">
                        <ul>
                          <li>
                            <a
                              href="https://www.facebook.com/"
                              target="_blank"
                              rel="noreferrer"
                              aria-label="Facebook"
                            >
                              <IconContext.Provider value={{ size: "14px" }}>
                                <BsFacebook />
                              </IconContext.Provider>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/"
                              target="_blank"
                              rel="noreferrer"
                              aria-label="LinkedIn"
                            >
                              <IconContext.Provider value={{ size: "14px" }}>
                                <BsLinkedin />
                              </IconContext.Provider>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="team-content text-center">
                      <h3>Jo Ann Grace Militante-Yu</h3>
                      <p>CPA | Domain Specialist - Government Systems</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-team-member">
                    <div className="team-img">
                      <a href="#chris">
                        <img src={chrisPhoto} alt="Chris Mae Melo" />
                      </a>
                      <div className="team-social-icon text-center">
                        <ul>
                          <li>
                            <a
                              href="https://www.facebook.com/"
                              target="_blank"
                              rel="noreferrer"
                              aria-label="Facebook"
                            >
                              <IconContext.Provider value={{ size: "14px" }}>
                                <BsFacebook />
                              </IconContext.Provider>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/"
                              target="_blank"
                              rel="noreferrer"
                              aria-label="LinkedIn"
                            >
                              <IconContext.Provider value={{ size: "14px" }}>
                                <BsLinkedin />
                              </IconContext.Provider>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="team-content text-center">
                      <h3>Chrisna Mae A. Melo</h3>
                      <div className="d-none d-lg-block d-xxl-none">&nbsp;</div>
                      <p>Junior Software Engineer</p>
                      <br className="d-none d-lg-block d-xxl-none" />
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-team-member">
                    <div className="team-img">
                      <a href="#jaemily">
                        <img src={jaemilyPhoto} alt="Jaemily Cadimas" />
                      </a>
                      <div className="team-social-icon text-center">
                        <ul>
                          <li>
                            <a
                              href="https://www.facebook.com/"
                              target="_blank"
                              rel="noreferrer"
                              aria-label="Facebook"
                            >
                              <IconContext.Provider value={{ size: "14px" }}>
                                <BsFacebook />
                              </IconContext.Provider>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/in/chrisna-mae-melo-2b5718285//"
                              target="_blank"
                              rel="noreferrer"
                              aria-label="LinkedIn"
                            >
                              <IconContext.Provider value={{ size: "14px" }}>
                                <BsLinkedin />
                              </IconContext.Provider>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="team-content text-center">
                      <h3>Jaemily Galinato Cadimas</h3>
                      <div className="d-none d-lg-block d-xxl-none">&nbsp;</div>
                      <p>Junior Software Engineer</p>
                      <br className="d-none d-lg-block d-xxl-none" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Trivut Team Section - End */}

        {/* Marketing Sections - Big & Small */}
        <MarketingSectionBig />
        <MarketingSectionSmall />

        {/* Contract Form */}
        <ContactForm />
      </main>

      {/* Footer Section */}
      <Footer />
    </>
  );
};

export default HomePage;
