import { Link } from "react-router-dom";

import TopNav from "../components/topNav";
import SideNav from "../components/sideNav";
import FooterSection from "../components/footer";

import { PiDotOutlineDuotone } from "react-icons/pi";

const Notes = () => {
  return (
    <>
      <SideNav />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <TopNav page={"Notes"} />
        <div className="container-fluid">
          <h1 className="fs-5 text-dark">Meeting Notes</h1>
          <div className="row">
            <div className="card m-2 col-5">
              <div className="card-body">
                <h5 className="card-title">Meeting Notes 07/07/23 (Friday)</h5>
                <p className="card-text">
                  <ul>
                    <li>
                      <PiDotOutlineDuotone />
                      Prioritize "Nurse-On Demand" Application
                    </li>
                    <li>
                      <PiDotOutlineDuotone />
                      App will be developed purely in React-Native for mobile
                      devices
                    </li>
                    <li>
                      <PiDotOutlineDuotone />
                      (Peter/Jason) To think ahead how to market the application
                      after launch
                    </li>
                    <li>
                      <PiDotOutlineDuotone />
                      Continue efforts in marketing the training program
                      (LinkedIn/Facebook)
                    </li>
                  </ul>
                </p>
                <br />
                <a
                  href="https://trello.com/b/aGqo1fGo/nurse-on-call-app"
                  rel="noreferrer"
                  target="_blank"
                  className="btn btn-primary"
                >
                  Nurse-On Demand Trello Board
                </a>
              </div>
            </div>
            <div className="card m-2 col-5">
              <div className="card-body">
                <h5 className="card-title">Notes from 07/25/23 (Tuesday)</h5>
                <p className="card-text">
                  <ul>
                    {/* <li>
                      <PiDotOutlineDuotone />
                      Nurse On-Demand App Questions on payment
                    </li> */}
                    <li>
                      <PiDotOutlineDuotone />
                      Payment options to include Cash, Credit/Debit Card,
                      Digital Banks and Togo tokens shared by holder as
                      philantrophy
                    </li>
                    <li>
                      <PiDotOutlineDuotone />
                      For Nurse-On Demand App accelerators is not necessary.
                    </li>
                    <li>
                      <PiDotOutlineDuotone />
                      Additional Roles for app include{" "}
                      <u>Physical Therapists</u> and <u>Caregivers</u> to
                      provide their specialty services
                    </li>
                    <li>
                      <PiDotOutlineDuotone />
                      Full notes can be referenced in Telegram "Trivut -
                      Healthcare Platform" Group Chat
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card m-2 col-5">
              <div className="card-body">
                <h5 className="card-title">Notes from 08/31/23</h5>
                <p className="card-text">
                  <ul>
                    <li>
                      <PiDotOutlineDuotone />
                      Focus on NOD payments feature for now
                    </li>
                    <li>
                      <PiDotOutlineDuotone />
                      Research Actors/Entities
                    </li>
                    <li>
                      <PiDotOutlineDuotone />
                      Payment System can be a micro-app/reusable for other
                      systems
                    </li>
                    <li>
                      <PiDotOutlineDuotone />
                      Continue Lessons in preparation for NOD App
                    </li>
                    {/* <li>
                      <PiDotOutlineDuotone />
                      Lessons will be on every <u>Thu</u> and <u>Fri</u> at{" "}
                      <u>7:00pm</u>
                    </li> */}
                    <li>
                      <PiDotOutlineDuotone />
                      Lessons will be on every <u>Sat</u> at <u>7:00pm</u>
                    </li>
                  </ul>
                </p>
                {/* <a href="#" className="btn btn-primary">
                  Go somewhere
                </a> */}
              </div>
            </div>
          </div>
        </div>
        <FooterSection />
      </main>
    </>
  );
};

export default Notes;
