import TopNav from "../components/topNav";
import SideNav from "../components/sideNav";
import FooterSection from "../components/footer";

const TraineeProjects = () => {
  return (
    <>
      <SideNav />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <TopNav page={"Training Projects"} />
        <div className="container-fluid">
          <h1 className="fs-5 text-dark">DevJedi Projects</h1>
          <div className="row">
            <div className="col-4">
              <p>
                <a
                  className="btn btn-primary"
                  data-bs-toggle="collapse"
                  href="#collapseProject1"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseProject1"
                >
                  Nurse On-Demand App
                </a>
              </p>
              <div className="collapse mt-n2" id="collapseProject1">
                <div className="card card-body border border-secondary">
                  This project is all hands on deck.
                  <div className="row">
                    <div>
                      <span className="badge text-bg-dark">All *</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <p>
                <a
                  className="btn btn-primary"
                  data-bs-toggle="collapse"
                  href="#collapseProject2"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseProject2"
                >
                  Doctors Appointment System
                </a>
              </p>
              <div className="collapse mt-n2" id="collapseProject2">
                <div className="card card-body border border-secondary">
                  Project is assigned to Keith and Pat.
                  <div className="row">
                    <div>
                      <span className="badge text-bg-dark">Keith</span>
                    </div>
                    <div>
                      <span className="badge text-bg-dark">Pat</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <p>
                <a
                  className="btn btn-primary"
                  data-bs-toggle="collapse"
                  href="#collapseProject3"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseProject3"
                >
                  School Administration System
                </a>
              </p>
              <div className="collapse mt-n2" id="collapseProject3">
                <div className="card card-body border border-secondary">
                  A project by Korrene and Lingki
                  <div className="row">
                    <div>
                      <span className="badge text-bg-dark">Korrene</span>
                    </div>
                    <div>
                      <span className="badge text-bg-dark">Lingki</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <p>
                <a
                  className="btn btn-primary"
                  data-bs-toggle="collapse"
                  href="#collapseProject4"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseProject4"
                >
                  Subscription and Cancellation System
                </a>
              </p>
              <div className="collapse mt-n2" id="collapseProject4">
                <div className="card card-body border border-secondary">
                  A project by Korrene and Lingki
                  <div className="row">
                    <div>
                      <span className="badge text-bg-dark">Korrene</span>
                    </div>
                    <div>
                      <span className="badge text-bg-dark">Lingki</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <p>
                <a
                  className="btn btn-primary"
                  data-bs-toggle="collapse"
                  href="#collapseProject5"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseProject5"
                >
                  WHO Growth Chart
                </a>
              </p>
              <div className="collapse mt-n2" id="collapseProject5">
                <div className="card card-body border border-secondary">
                  A collaborative DevJedi project
                  <div className="row">
                    <div>
                      <span className="badge text-bg-dark">Vincent</span> - will
                      guide
                    </div>
                    <div>
                      <span className="badge text-bg-dark">Pat</span>
                    </div>
                    <div>
                      <span className="badge text-bg-dark">Keith</span>
                    </div>
                    <div>
                      <span className="badge text-bg-dark">Chris</span>
                    </div>
                    <div>
                      <span className="badge text-bg-dark">Jaemily</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <p>
                <a
                  className="btn btn-primary"
                  data-bs-toggle="collapse"
                  href="#collapseProject6"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseProject6"
                >
                  Medication Management App
                </a>
              </p>
              <div className="collapse mt-n2" id="collapseProject6">
                <div className="card card-body border border-secondary">
                  A collaborative DevJedi project
                  <div className="row">
                    <div>
                      <span className="badge text-bg-dark">Lingki</span>
                    </div>
                    <div>
                      <span className="badge text-bg-dark">Korrene</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterSection />
      </main>
    </>
  );
};

export default TraineeProjects;
