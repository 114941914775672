import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";

import logo from "../img/logo/logo-small-nobg.png";
import termsAndConditionsImg from "../img/hero-carousel/terms-and-conditions.webp";

const TermsAndConditions = () => {
  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex justify-content-between">
          <div className="logo">
            <h1>
              <a href="/">
                <img src={logo} alt="logo" className="img-fluid" />{" "}
                <span> Trivut Corp.</span>
              </a>
            </h1>
          </div>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className="nav-link scrollto" href="/">
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about">
                  Terms and Conditions
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#contact">
                  Contact Us
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </div>
      </header>

      <section id="hero">
        <div
          id="heroCarousel"
          className="carousel slide carousel-fade"
          data-bs-ride="true"
        >
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="5000">
              <img
                src={termsAndConditionsImg}
                className="d-block h-100"
                alt="Terms and Conditions"
              />
              <div className="carousel-caption carousel-container">
                <div className="container">
                  <h2 className="animate__animated animate__fadeInDown">
                    Terms and Conditions
                  </h2>
                  <p className="animate__animated animate__fadeInUp">
                    Please take a moment to read through our terms and
                    conditions, and if you have any questions, feel free to
                    contact us.{" "}
                  </p>
                  <a
                    href="#about"
                    className="btn-get-started scrollto animate__animated animate__fadeInUp"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <div id="about" className="about-area area-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="section-headline text-center">
                  <h2>Terms and Conditions</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <p>
                  These terms and conditions ("Agreement") govern your use of
                  the software development outsourcing services ("Services")
                  provided by Trivut Corp. By using our Services, you agree to
                  be bound by this Agreement.
                </p>
                <h5>Service Provision</h5>
                <p>
                  Trivut Corp. agrees to provide software development
                  outsourcing services to you in accordance with the project
                  requirements and specifications agreed upon by both parties.
                  We will use commercially reasonable efforts to complete the
                  project within the agreed upon timeframe and budget.
                </p>
                <h5>Intellectual Property</h5>
                <p>
                  All intellectual property rights, including but not limited to
                  copyright, trademark, and patent, in any materials developed
                  or produced by Trivut Corp. during the project will remain the
                  property of Trivut Corp. unless otherwise agreed upon in
                  writing by both parties.
                </p>
                <h5>Confidentiality</h5>
                <p>
                  Trivut Corp. agrees to maintain the confidentiality of any
                  proprietary or confidential information provided by you,
                  including but not limited to project specifications, business
                  plans, and customer data. We will not disclose or use such
                  information for any purpose other than to provide the Services
                  to you.
                </p>
                <h5>Warranty and Liability</h5>
                <p>
                  Trivut Corp. warrants that the Services will be performed in a
                  professional and workmanlike manner. However, we do not
                  warrant that the Services will be error-free or uninterrupted.
                  In no event shall Trivut Corp. be liable to you for any
                  indirect, incidental, or consequential damages arising out of
                  or in connection with the Services.
                </p>
                <h5>Indemnification</h5>
                <p>
                  You agree to indemnify and hold harmless Trivut Corp. and its
                  officers, directors, employees, and agents from and against
                  any and all claims, damages, liabilities, costs, and expenses
                  arising out of or in connection with your use of the Services.
                </p>
                <h5>Termination</h5>
                <p>
                  Either party may terminate this Agreement at any time upon
                  written notice to the other party. Upon termination, Trivut
                  Corp. will provide you with all work product completed as of
                  the termination date.
                </p>
                {/* <h5>Governing Law and Dispute Resolution</h5>
                    <p>This Agreement shall be governed by and construed in accordance with the laws of [State/Country]. Any dispute arising out of or in connection with this Agreement shall be resolved through arbitration in accordance with the rules of the American Arbitration Association. </p> */}
                <h5>Entire Agreement</h5>
                <p>
                  This Agreement constitutes the entire understanding and
                  agreement between Trivut Corp. and you with respect to the
                  Services. This Agreement supersedes all prior negotiations,
                  understandings, and agreements, whether written or oral,
                  between the parties with respect to the subject matter hereof.
                </p>
                <h5>Modification</h5>
                <p>
                  Trivut Corp. reserves the right to modify this Agreement at
                  any time by posting the modified terms on our website. Your
                  continued use of the Services after any such modification
                  shall constitute your acceptance of the modified terms.
                </p>
                <h5>Contact Us</h5>
                <p>
                  If you have any questions or concerns about this Agreement,
                  please contact us at{" "}
                  <a href="mailto: corp@trivut.com">corp@trivut.com</a> and +63
                  961 560 1730.
                </p>
              </div>
            </div>
          </div>
        </div>

        <ContactForm />
      </main>

      <Footer />
    </>
  );
};

export default TermsAndConditions;
