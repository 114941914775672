import HeaderLogo from "../components/HeaderLogo";
import MarketingSectionSmall from "../components/MarketingSectionSmall";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";

import doctrackingHero from "../img/hero-carousel/document-tracking.webp";

import { IconContext } from "react-icons";
import { TbCloudComputing } from "react-icons/tb";
import { GrShieldSecurity } from "react-icons/gr";
import { FaSearchLocation } from "react-icons/fa";
import { FcMultipleDevices } from "react-icons/fc";
import { FcTimeline } from "react-icons/fc";
import { FaUsersCog } from "react-icons/fa";
import { FcExpired } from "react-icons/fc";
import { MdOutlineQrCode2 } from "react-icons/md";

const DocumentTrackingSystem = () => {
  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex justify-content-between">
          <HeaderLogo />

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className="nav-link scrollto" href="/">
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about">
                  Our Document Tracking Solution
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#services">
                  Document Tracking System Features
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#contact">
                  Contact Us
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <section id="hero">
        <div
          id="heroCarousel"
          className="carousel slide carousel-fade"
          data-bs-ride="true"
        >
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="5000">
              <img
                src={doctrackingHero}
                className="d-block h-100"
                alt="Document Tracking Made Easy"
              />
              <div className="carousel-caption carousel-container">
                <div className="container">
                  <h2 className="animate__animated animate__fadeInDown">
                    Document Tracking Made Easy
                  </h2>
                  {/* <p className="animate__animated animate__fadeInUp">We deliver cutting-edge software solutions using our deep technical expertise and years of experience to keep you ahead</p> */}
                  <p className="animate__animated animate__fadeInUp">
                    Take control of your document workflow and simplify your
                    business processes with our reliable document tracking
                    system
                  </p>
                  <a
                    href="#contact"
                    className="btn-get-started scrollto animate__animated animate__fadeInUp"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        {/* About Section - Start */}
        <div id="about" className="about-area area-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="section-headline text-center">
                  <h2>Our Document Tracking Solution</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <p>
                  In today's fast-paced business environment, document tracking
                  is an essential component of any successful organization's
                  workflow. The ability to track documents efficiently and
                  effectively can significantly increase productivity, reduce
                  errors, and improve collaboration. Our cloud-based document
                  tracking solution provides a comprehensive and innovative
                  solution for managing your document workflow.
                </p>
                <p>
                  Our document tracking system is built on the Amazon Web
                  Services (AWS) cloud, providing you with the highest level of
                  security, reliability, and scalability. Our solution is
                  designed to ensure that your documents are always available,
                  regardless of your location or device. With our multi-device
                  compatibility, you can access and track your documents from
                  any device, including desktop computers, laptops, tablets, and
                  smartphones.
                </p>
                <p>
                  Our document tracking system is also designed with security in
                  mind. With permission-based access security, you can control
                  who has access to your documents, ensuring that only
                  authorized users can view, edit, or share your files.
                  Additionally, our audit trail feature provides a detailed
                  record of every action taken on your documents, allowing you
                  to track changes and monitor your documents' activity over
                  time.
                </p>
                <p>
                  Our document tracking solution is user-friendly and easy to
                  use. The intuitive user interface provides a seamless
                  experience, allowing you to track your documents with just a
                  few clicks. With our drag-and-drop functionality, you can
                  quickly upload and organize your documents, making it easy to
                  find what you need when you need it.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* About Section - End */}

        {/* Technology Stack - Start */}
        <div id="services" className="services-area area-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="section-headline services-head text-center">
                  <h3>Document Tracking System Features</h3>
                </div>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-md-3 col-sm-3 col-xs-12">
                <div className="about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <a className="services-icon">
                        <IconContext.Provider
                          value={{ size: "1.9em", color: "#FF9900" }}
                        >
                          <TbCloudComputing />
                        </IconContext.Provider>
                      </a>
                      <h4>
                        <br />
                        Cloud-based
                      </h4>
                      <p>
                        Effortlessly manage your documents from anywhere with
                        our cloud-based document tracking system hosted on the
                        secure and reliable Amazon Web Services (AWS).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-xs-12">
                <div className="about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <a className="services-icon">
                        <IconContext.Provider
                          value={{ size: "1.8em", color: "darkBlue" }}
                        >
                          <GrShieldSecurity />
                        </IconContext.Provider>
                      </a>
                      <h4>
                        <br />
                        Security
                      </h4>
                      <p>
                        Secure your sensitive documents with confidence using
                        our document tracking system, providing robust security
                        measures to protect your information from unauthorized
                        access.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-xs-12">
                <div className=" about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <a className="services-icon" href="#">
                        <IconContext.Provider
                          value={{ size: "1.8em", color: "gray" }}
                        >
                          {/* <SlScreenDesktop /><SlScreenSmartphone /><SlScreenTablet /> */}
                          <FcMultipleDevices />
                        </IconContext.Provider>
                      </a>
                      <h4>Multi-device Compatibility</h4>
                      <p>
                        Stay connected to your documents on-the-go with our
                        document tracking system's multi-device compatibility,
                        providing a seamless experience across all your devices.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-xs-12">
                <div className=" about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <a className="services-icon">
                        <IconContext.Provider
                          value={{ size: "1.8em", color: "#008bb9" }}
                        >
                          <FaSearchLocation />
                        </IconContext.Provider>
                      </a>
                      <h4>
                        <br />
                        Real-time Tracking{" "}
                      </h4>
                      <p>
                        Get real-time visibility into your document's status and
                        location with our document tracking system, allowing you
                        to stay on top of critical updates and deadlines.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-xs-12">
                <div className=" about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <a className="services-icon">
                        <IconContext.Provider
                          value={{ size: "1.8em", color: "#000" }}
                        >
                          <FcTimeline />
                        </IconContext.Provider>
                      </a>
                      <h4>
                        <br />
                        Audit Trail{" "}
                      </h4>
                      <p>
                        Ensure accountability and compliance with our document
                        tracking system's audit trail feature, providing a
                        detailed record of all document-related activities and
                        changes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-xs-12">
                <div className=" about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <a className="services-icon">
                        <IconContext.Provider
                          value={{ size: "1.8em", color: "darkBlue" }}
                        >
                          <FaUsersCog />
                        </IconContext.Provider>
                      </a>
                      <h4>
                        <br />
                        User Management
                      </h4>
                      <p>
                        Effortlessly manage user access and permissions with our
                        document tracking system's user management feature,
                        streamlining your collaboration process.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-xs-12">
                <div className=" about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <a className="services-icon">
                        <IconContext.Provider
                          value={{ size: "1.8em", color: "#61DBFB" }}
                        >
                          <FcExpired />
                        </IconContext.Provider>
                      </a>
                      <h4>Warnings and Notifications</h4>
                      <p>
                        Stay informed and in control with our document tracking
                        system's warning and notification feature, alerting you
                        to any changes or issues with your critical documents in
                        real-time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-xs-12">
                <div className=" about-move">
                  <div className="services-details">
                    <div className="single-services">
                      <a className="services-icon">
                        <IconContext.Provider
                          value={{ size: "1.8em", color: "#000" }}
                        >
                          <MdOutlineQrCode2 />
                        </IconContext.Provider>
                      </a>
                      <h4>
                        <br />
                        QR Code Integration
                      </h4>
                      {/* <p>
                        Maximize your hardware investment with our document
                        tracking system's barcode compatibility, seamlessly
                        integrating with your existing barcode scanning
                        equipment.
                      </p> */}
                      <p>
                        Efficient QR integration facilitates seamless document
                        management and access across smartphones, tablets, and
                        QR scanners for enhanced productivity.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Technology Stack - End */}

        {/* Marketing Section - Small */}
        <MarketingSectionSmall />

        {/* Contact Form Section */}
        <ContactForm />
      </main>

      {/* Footer Section */}
      <Footer />
    </>
  );
};

export default DocumentTrackingSystem;
