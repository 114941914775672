import HeaderLogo from "../components/HeaderLogo";
import MarketingSectionBig from "../components/MarketingSectionBig";
import MarketingSectionSmall from "../components/MarketingSectionSmall";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";

import careersImg from "../img/hero-carousel/careers.webp";

const Careers = () => {
  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex justify-content-between">
          <HeaderLogo />

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className="nav-link scrollto" href="/">
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about">
                  Careers
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#contact">
                  Contact Us
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <section id="hero">
        <div
          id="heroCarousel"
          className="carousel slide carousel-fade"
          data-bs-ride="true"
        >
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="5000">
              <img src={careersImg} className="d-block h-100" alt="..." />
              <div className="carousel-caption carousel-container">
                <div className="container">
                  <h2 className="animate__animated animate__fadeInDown">
                    Join Our Team
                  </h2>
                  <p className="animate__animated animate__fadeInUp">
                    Be a part of our passionate team and make an impact in a
                    fast-paced and collaborative environment.
                  </p>
                  <a
                    href="#contact"
                    className="btn-get-started scrollto animate__animated animate__fadeInUp"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <div id="about" className="about-area area-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="section-headline text-center">
                  <h2>Careers</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <p>
                  If you're a software developer seeking an environment that's
                  dynamic and innovative, you don't need to search any further
                  than Trivut Corp.
                </p>
                <p>
                  We believe in investing in our employees' professional
                  development. We offer opportunities for training, mentorship,
                  and career advancement to ensure that our team members have
                  the support they need to reach their full potential.
                </p>
                <p>
                  Our culture is one of collaboration and teamwork. We believe
                  that our differences make us stronger, and we are committed to
                  creating an inclusive and welcoming environment where everyone
                  feels valued and respected.
                </p>
                <p>
                  We are looking for talented software developers who share our
                  passion for innovation and excellence. Our ideal candidate is
                  someone who is curious, collaborative, and driven to create
                  solutions that make a positive impact in the world. Whether
                  you are a recent graduate or a seasoned software developer, we
                  welcome applicants at all levels of experience.
                </p>
                <p>
                  We are seeking candidates for software development who have
                  experience and proficiency in HTML, CSS, and Javascript.
                  Additionally, experience with NodeJS, ReactJS, PostgreSQL, and
                  ExpressJS is not mandatory but would be considered
                  advantageous.
                </p>
                <p>
                  If you are looking for an exciting and challenging career in
                  software development, we invite you to apply to join our team.
                  We are looking for innovative and talented software developers
                  who are passionate about creating cutting-edge solutions that
                  make a difference in the world. We look forward to hearing
                  from you!
                </p>
                <p>
                  For interested applicants, you may send a{" "}
                  <b>one page essay</b> stating why you want to be part of
                  Trivut and your <b>resume</b> to our email{" "}
                  <a href="mailto: sales@trivut.com">
                    <u>sales@trivut.com</u>
                  </a>
                  .
                </p>
              </div>
              <div className="mt-4 col-md-12 col-sm-12 col-xs-12">
                <hr className="mb-5" />
                <p>
                  We regularly post job and training opportunities on our{" "}
                  <a
                    href="https://www.facebook.com/people/Trivut-Corp/100086523165246/"
                    rel="noreferrer"
                    target="_blank"
                    className="fw-bold"
                  >
                    Facebook{" "}
                  </a>
                  and{" "}
                  <a
                    href="https://www.linkedin.com/company/trivut-corp/"
                    rel="noreferrer"
                    target="_blank"
                    className="fw-bold"
                  >
                    LinkedIn
                  </a>{" "}
                  pages, so make sure you <em>Like</em> and <em>Follow</em> our
                  pages for latest posts and openings.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Marketing Section - Big  */}
        <MarketingSectionBig />

        {/* Marketing Section - Small */}
        <MarketingSectionSmall />

        {/* Contact Form Section */}
        <ContactForm />
      </main>

      {/* Footer Section */}
      <Footer />
    </>
  );
};

export default Careers;
