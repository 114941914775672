import { Link } from "react-router-dom";

const TopNav = ({ page }) => {
  return (
    <>
      <nav
        className="navbar-dashboard navbar-main-dashboard navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
        id="navbarBlur"
        navbar-scroll="true"
      >
        <div className="container-fluid py-1 px-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
              <li className="breadcrumb-item text-sm">
                <a href="#" className="opacity-5 text-dark">
                  Pages
                </a>
              </li>
              <li
                className="breadcrumb-item text-sm text-dark active"
                aria-current="page"
              >
                Dashboard
              </li>
            </ol>
            <h6 className="font-weight-bolder mb-0">{page}</h6>
          </nav>
          <div
            className="collapse-dashboard navbar-collapse-dashboard mt-sm-0 mt-2 me-md-0 me-sm-4"
            id="navbar"
          >
            <div className="ms-md-auto pe-md-3 d-flex align-items-center">
              <div className="input-group">
                <span className="input-group-text text-body">
                  <i className="fas fa-search" aria-hidden="true"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here..."
                />
              </div>
            </div>
            <ul className="navbar-nav justify-content-end">
              {/* <li className=".nav-item d-xl-none ps-3 d-flex align-items-center">
                <a
                  href="#"
                  className="nav-link text-body p-0"
                  id="iconNavbarSidenav"
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line"></i>
                    <i className="sidenav-toggler-line"></i>
                    <i className="sidenav-toggler-line"></i>
                  </div>
                </a>
              </li> */}

              {/* <li className=".nav-item pe-3 d-flex align-items-center">
                <a href="#" className="nav-link text-body p-0">
                  <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer"></i>
                </a>
              </li> */}

              <li class="nav-item dropdown me-3 d-xl-none d-xxl-block">
                <a
                  className="nav-link text-body font-weight-bold dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Dashboard
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <Link to="/dashboard" className="dropdown-item">
                      Dashbord Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/dashboard/projects" className="dropdown-item">
                      Projects
                    </Link>
                  </li>
                  <li>
                    <Link to="/dashboard/notes" className="dropdown-item">
                      Notes
                    </Link>
                  </li>
                  <li>
                    <Link to="/dashboard/calendar" className="dropdown-item">
                      Calendar
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/dashboard/reports-and-analytics"
                      className="dropdown-item"
                    >
                      Reports
                    </Link>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <Link
                      to="/dashboard/training/attendance"
                      className="dropdown-item"
                    >
                      DevJedi Attendance
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/dashboard/training/progress-tracker"
                      className="dropdown-item"
                    >
                      DevJedi Progress Tracker
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/dashboard/training/projects"
                      className="dropdown-item"
                    >
                      DevJedi Projects
                    </Link>
                  </li>
                </ul>
              </li>
              {/* x */}
              <li className="nav-item pe-3 d-flex align-items-center">
                <a
                  href="#"
                  className="nav-link text-body font-weight-bold px-0"
                >
                  <i className="fa fa-user me-sm-1"></i>
                  <span className="d-sm-inline d-none">Sign Out</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* <div className="fixed-plugin">
        <a
          href="#"
          className="fixed-plugin-button text-dark position-fixed px-3 py-2"
        >
          <i className="fa fa-cog py-2"> </i>
        </a>
        <div className="card shadow-lg">
          <div className="card-header pb-0 pt-3">
            <div className="float-start">
              <h5 className="mt-3 mb-0">Dashboard Settings</h5>
            </div>
            <div className="float-end mt-4">
              <button className="btn btn-link text-dark p-0 fixed-plugin-close-button">
                <i className="fa fa-close"></i>
              </button>
            </div>
          </div>
          <hr className="horizontal dark my-1" />
          <div className="card-body pt-sm-3 pt-0">
            <div>
              <h6 className="mb-0">Sidebar Colors</h6>
            </div>
            <a href="#" className="switch-trigger background-color">
              <div className="badge-colors my-2 text-start">
                <span
                  className="badge filter bg-gradient-primary active"
                  data-color="primary"
                  onClick="sidebarColor(this)"
                ></span>
                <span
                  className="badge filter bg-gradient-dark"
                  data-color="dark"
                  onClick="sidebarColor(this)"
                ></span>
                <span
                  className="badge filter bg-gradient-info"
                  data-color="info"
                  onClick="sidebarColor(this)"
                ></span>
                <span
                  className="badge filter bg-gradient-success"
                  data-color="success"
                  onClick="sidebarColor(this)"
                ></span>
                <span
                  className="badge filter bg-gradient-warning"
                  data-color="warning"
                  onClick="sidebarColor(this)"
                ></span>
                <span
                  className="badge filter bg-gradient-danger"
                  data-color="danger"
                  onClick="sidebarColor(this)"
                ></span>
              </div>
            </a>

            <div className="mt-3">
              <h6 className="mb-0">Sidenav Type</h6>
              <p className="text-sm">
                Choose between 2 different sidenav types.
              </p>
            </div>
            <div className="d-flex">
              <button
                className="btn bg-gradient-primary w-100 px-3 mb-2 active"
                data-className="bg-transparent"
                onClick="sidebarType(this)"
              >
                Transparent
              </button>
              <button
                className="btn bg-gradient-primary w-100 px-3 mb-2 ms-2"
                data-className="bg-white"
                onClick="sidebarType(this)"
              >
                White
              </button>
            </div>
            <p className="text-sm d-xl-none d-block mt-2">
              You can change the sidenav type just on desktop view.
            </p>

            <div className="mt-3">
              <h6 className="mb-0">Navbar Fixed</h6>
            </div>
            <div className="form-check form-switch ps-0">
              <input
                className="form-check-input mt-1 ms-auto"
                type="checkbox"
                id="navbarFixed"
                onClick="navbarFixed(this)"
              />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default TopNav;
