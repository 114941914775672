import { Helmet } from "react-helmet";

const TitlesAndDescriptions = ({ page }) => {
  const titles = {
    // Main Site
    home: "Trivut Corp. | Digital Transformation and Innovation",
    careers: "Trivut Corp. | Careers",
    dts: "Trivut Corp. | Document Tracking System",
    expertise: "Trivut Corp. | Our Expertise",
    outsourcing: "Trivut Corp. | Why Outsource?",
    "privacy-policy": "Trivut Corp. | Privacy Policy",
    "terms-and-conditions": "Trivut Corp. | Terms and Conditions",
    "error-page": "Trivut Corp. | 404 Page Not Found",
    // Dashboard
    "dashboard-home": "Trivut Corp. | Dashboard Home",
    "dashboard-projects": "Trivut Corp. | Projects",
    "dashboard-notes": "Trivut Corp. | Notes",
    "dashboard-calendar": "Trivut Corp. | Calendar",
    "dashboard-reports": "Trivut Corp. | Reports and Analytics",
    "dashboard-trainee-attendance": "Trivut Corp. | Trainee Attendance",
    "dashboard-trainee-progress-tracker":
      "Trivut Corp. | Trainee Progress Tracker",
    "dashboard-trainee-projects": "Trivut Corp. | Trainee Projects",
  };

  const descriptions = {
    // Main Site
    home: "Trivut Corporation envisions transformation, digitization, and automation of processes into application software that are vital in our evolving world for private, commercial, and government entities.",
    careers:
      "Explore exciting career opportunities and join our passionate team at Trivut Corp. Discover a wide range of rewarding careers that empower you to make a meaningful impact. Find your perfect fit and grow professionally in a dynamic and inclusive work environment. Join us and shape your future with Trivut Corp.",
    dts: "Our document tracking system is built on the Amazon Web Services (AWS) cloud, providing you with the highest level of security, reliability, and scalability. Our solution is designed to ensure that your documents are always available, regardless of your location or device. With our multi-device compatibility, you can access and track your documents from any device, including desktop computers, laptops, tablets, and smartphones.",
    expertise:
      "Introducing our cutting-edge software solution, built to revolutionize the way businesses operate in the digital age. Our cloud-based platform offers unparalleled scalability, flexibility, and reliability to help you achieve your goals faster and more efficiently than ever before.",
    outsourcing:
      "Developing software in-house can be a time-consuming and expensive process. This is where software development outsourcing comes in. Outsourcing your software development needs can be a smart move for your business, as it allows you to focus on your core competencies while benefiting from the expertise of a specialized team.",
    "privacy-policy":
      "Protecting your privacy is our top priority. We are committed to ensuring that any personal information you share with us is kept secure and confidential.",
    "terms-and-conditions":
      "Review the terms and conditions of Trivut Corp. to understand the guidelines and agreements governing your use of our services. Gain clarity on important policies, privacy measures, and legal obligations. Our transparent and comprehensive terms and conditions ensure a fair and secure experience for all users. Stay informed and make informed decisions with Trivut Corp’s terms and conditions.",
    "error-page":
      "404 Page Not Found: It appears you've ventured into uncharted territory. The page you're searching for seems to have vanished into the digital abyss. We apologize for the inconvenience.",
    // Dashboard
    dashboard:
      "Purpose-built dashboard empowers you to monitor and analyze crucial metrics, gain valuable insights, and make informed decisions to enhance your online performance.",
    "dashboard-projects":
      "Manage and oversee your projects with our intuitive website dashboard. Our project management tools on the dashboard streamline collaboration, boost productivity, and keep your team organized. ",
    "dashboard-notes":
      "Stay organized and capture your thoughts with ease using our convenient notes page on the website dashboard. Jot down ideas, create to-do lists, and record important reminders in one central location. ",
    "dashboard-calendar":
      "Manage your schedule and stay on top of important events with our dynamic calendar page on the website dashboard. Our interactive calendar provides a comprehensive view of your upcoming tasks, deadlines, and appointments. ",
    "dashboard-reports":
      "View insights and make data-driven decisions with our reports page on the website dashboard. Track key metrics, analyze trends, and measure the effectiveness of your strategies.",
    "dashboard-attendance":
      "Manage and track attendance with our attendance page on the website dashboard. Enhance efficiency, improve accountability, and gain valuable insights into attendance patterns.",
    "dashboard-progress-tracker":
      "Visualize and track progress towards your goals with our progress tracker page on the website dashboard. Set targets, milestones, and key performance indicators (KPIs) to monitor your projects, campaigns, or personal objectives. ",
    "dashboard-training-projects":
      "Monitor and evaluate trainee progress with our trainee progress tracker page on the website dashboard. Track individual trainee performance, set learning goals, and measure progress over time. ",
  };

  const title = titles[page];
  const metaDescription = descriptions[page];

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
      <meta name="title" key="title" content={title} />
      <meta property="og:title" key="og:title" content={title} />
      <meta
        property="og:description"
        key="og:description"
        content={metaDescription}
      />
    </Helmet>
  );
};

export default TitlesAndDescriptions;
