import HeaderLogo from "../components/HeaderLogo";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";

import privacyImg from "../img/hero-carousel/privacy-policy.webp";

const PrivacyPolicy = () => {
  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex justify-content-between">
          <HeaderLogo />

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className="nav-link scrollto" href="/">
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#contact">
                  Contact Us
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <section id="hero">
        <div
          id="heroCarousel"
          className="carousel slide carousel-fade"
          data-bs-ride="true"
        >
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="5000">
              <img
                src={privacyImg}
                className="d-block h-100"
                alt="Privacy Policy"
              />
              <div className="carousel-caption carousel-container">
                <div className="container">
                  <h2 className="animate__animated animate__fadeInDown">
                    Privacy Policy
                  </h2>
                  <p className="animate__animated animate__fadeInUp">
                    Protecting your privacy is our top priority. We are
                    committed to ensuring that any personal information you
                    share with us is kept secure and confidential.
                  </p>
                  <a
                    href="#about"
                    className="btn-get-started scrollto animate__animated animate__fadeInUp"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <div id="about" className="about-area area-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="section-headline text-center">
                  <h2>Privacy Policy</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <h5>Privacy Policy</h5>
                <p>
                  At Trivut Corp., we are committed to protecting the privacy
                  and security of our clients' personal and business
                  information. This privacy policy explains how we collect, use,
                  and disclose data when you use our software development
                  outsourcing services.
                </p>
                <h5>Information Collection and Use</h5>
                <p>
                  We collect and use your personal and business information only
                  for the purposes of providing our software development
                  outsourcing services to you. We may collect information such
                  as your name, email address, phone number, business name, and
                  project requirements.
                </p>
                <p>
                  We do not share or sell your personal or business information
                  to third parties without your explicit consent. We may
                  disclose your information only if required to do so by law or
                  to comply with legal process.
                </p>
                <h5>Data Security</h5>
                <p>
                  We take reasonable and appropriate measures to protect your
                  personal and business information from unauthorized access,
                  disclosure, alteration, or destruction. We use
                  industry-standard security measures, such as encryption and
                  firewalls, to protect your data.
                </p>
                <h5>Data Retention</h5>
                <p>
                  We retain your personal and business information only for as
                  long as necessary to provide our software development
                  outsourcing services to you. We may retain your information
                  for longer periods if required by law or to comply with legal
                  obligations.
                </p>
                <h5>Changes to Privacy Policy</h5>
                <p>
                  We reserve the right to update or modify this privacy policy
                  at any time. We will notify you of any changes by posting the
                  updated policy on our website. Your continued use of our
                  software development outsourcing services after any changes to
                  this policy indicates your acceptance of the updated policy.
                </p>
                <h5>Contact Us</h5>
                <p>
                  If you have any questions or concerns about this privacy
                  policy, please contact us on the form at the bottom of the
                  page or alternatively at{" "}
                  <a href="mailto: corp@trivut.com">corp@trivut.com</a> or +63
                  961 560 1730.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Contact Form Section */}
        <ContactForm />
      </main>

      {/* Footer Section */}
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
