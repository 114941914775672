import TopNav from "../components/topNav";
import SideNav from "../components/sideNav";
import FooterSection from "../components/footer";

import { IconContext } from "react-icons";
import {
  DiHtml5,
  DiCss3,
  DiJsBadge,
  DiBootstrap,
  DiNodejsSmall,
  DiGit,
  DiReact,
  DiPostgresql,
  DiDocker,
  DiBrackets,
} from "react-icons/di";
import { AiFillCode, AiFillGitlab } from "react-icons/ai";
import { SiExpress } from "react-icons/si";

const TraineeProgressTracker = () => {
  return (
    <>
      <SideNav />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <TopNav page={"Training Tracker"} />
        <div className="container-fluid">
          <h1 className="fs-5 text-dark">DevJedi Progress Tracker</h1>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card h-100">
                <div className="card-header pb-0">
                  <h6>Keith</h6>
                  <p className="text-sm">
                    <span className="font-weight-bold">Start Date:</span> Dec.
                    13, 2022
                    <br />
                    <span className="font-weight-bold">Status:</span>{" "}
                    <span style={{ color: "green" }}>Active</span>
                  </p>
                </div>
                <div className="card-body p-3">
                  <div className="timeline timeline-one-side">
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#DC4B24",
                          }}
                        >
                          <DiHtml5 />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#0A6BB4",
                          }}
                        >
                          <DiCss3 />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML + CSS
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML / CSS Project
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#E84E31",
                            size: "1.6em",
                          }}
                        >
                          <DiGit />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#D94128",
                            size: "1.4em",
                          }}
                        >
                          <AiFillGitlab />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part II
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Git + GitLab
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#EFD81B",
                          }}
                        >
                          <DiJsBadge />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#7012F4",
                            size: "1.4em",
                          }}
                        >
                          <DiBootstrap />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part III
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          JavaScript + Bootstrap 5
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part III Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML / CSS / JavaScript / Bootstrap 5
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#5FA04F",
                            size: "1.8em",
                          }}
                        >
                          <DiNodejsSmall />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            size: "1.8em",
                          }}
                        >
                          <SiExpress />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part IV
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Basic Unix, SQL, NodeJS, ExpressJS
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <div className="timeline-block">
                        <span className="timeline-step">
                          <AiFillCode />
                        </span>
                        <div className="timeline-content">
                          <h6 className="text-dark text-sm font-weight-bold mb-0">
                            Part IV Project
                          </h6>
                          <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                            SQL / NodeJS / ExpressJS / Javascript
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#5ED3F3",
                            // size: "1.4em",
                          }}
                        >
                          <DiReact />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part V
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          ReactJS + React Hooks + React Routing and Styling +
                          JSON
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part V Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          ReactJS / SQL / JSON / Scripting languages
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#31658C",
                            size: "1.8em",
                          }}
                        >
                          <DiPostgresql />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#2863CD",
                            size: "2em",
                          }}
                        >
                          <DiDocker />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part VI
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Relational Database Design, PostgreSQL, Docker,
                          Kubernetes
                        </p>
                      </div>
                    </div>
                    {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part VI Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Full-stack Web Development
                        </p>
                      </div>
                    </div> */}
                    {/* <div className="timeline-block mb-3">
                    <span className="timeline-step">
                      <DiBrackets />
                    </span>
                    <div className="timeline-content">
                      <h6 className="text-dark text-sm font-weight-bold mb-0">
                        Part VII
                      </h6>
                      <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                        Object-Oriented Programming
                      </p>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card h-100">
                <div className="card-header pb-0">
                  <h6>Pat</h6>
                  <p className="text-sm">
                    <span className="font-weight-bold">Started:</span> Dec. 13,
                    2022
                    <br />
                    <span className="font-weight-bold">Status:</span>{" "}
                    <span style={{ color: "green" }}>Active</span>
                  </p>
                </div>
                <div className="card-body p-3">
                  <div className="timeline timeline-one-side">
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#DC4B24",
                          }}
                        >
                          <DiHtml5 />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#0A6BB4",
                          }}
                        >
                          <DiCss3 />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML + CSS
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML / CSS Project
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#E84E31",
                            size: "1.6em",
                          }}
                        >
                          <DiGit />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#D94128",
                            size: "1.4em",
                          }}
                        >
                          <AiFillGitlab />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part II
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Git + GitLab
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#EFD81B",
                          }}
                        >
                          <DiJsBadge />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#7012F4",
                            size: "1.4em",
                          }}
                        >
                          <DiBootstrap />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part III
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          JavaScript + Bootstrap 5
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part III Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML / CSS / JavaScript / Bootstrap 5
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#5FA04F",
                            size: "1.8em",
                          }}
                        >
                          <DiNodejsSmall />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            size: "1.8em",
                          }}
                        >
                          <SiExpress />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part IV
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Basic Unix, SQL, NodeJS, ExpressJS
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <div className="timeline-block">
                        <span className="timeline-step">
                          <AiFillCode />
                        </span>
                        <div className="timeline-content">
                          <h6 className="text-dark text-sm font-weight-bold mb-0">
                            Part IV Project
                          </h6>
                          <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                            SQL / NodeJS / ExpressJS / Javascript
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#5ED3F3",
                            // size: "1.4em",
                          }}
                        >
                          <DiReact />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part V
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          ReactJS + React Hooks + React Routing and Styling +
                          JSON
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part V Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          ReactJS / SQL / JSON / Scripting languages
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#31658C",
                            size: "1.8em",
                          }}
                        >
                          <DiPostgresql />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#2863CD",
                            size: "2em",
                          }}
                        >
                          <DiDocker />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part VI
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Relational Database Design, PostgreSQL, Docker,
                          Kubernetes
                        </p>
                      </div>
                    </div>
                    {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part VI Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Full-stack Web Development
                        </p>
                      </div>
                    </div> */}
                    {/* <div className="timeline-block mb-3">
                    <span className="timeline-step">
                      <DiBrackets />
                    </span>
                    <div className="timeline-content">
                      <h6 className="text-dark text-sm font-weight-bold mb-0">
                        Part VII
                      </h6>
                      <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                        Object-Oriented Programming
                      </p>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-sm-4 mt-lg-0">
              <div className="card h-100">
                <div className="card-header pb-0">
                  <h6>Korrene</h6>
                  <p className="text-sm">
                    <span className="font-weight-bold">Started:</span> Dec. 15,
                    2022
                    <br />
                    <span className="font-weight-bold">Status:</span>{" "}
                    <span style={{ color: "green" }}>Active</span>
                  </p>
                </div>
                <div className="card-body p-3">
                  <div className="timeline timeline-one-side">
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#DC4B24",
                          }}
                        >
                          <DiHtml5 />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#0A6BB4",
                          }}
                        >
                          <DiCss3 />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML + CSS
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML / CSS Project
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#E84E31",
                            size: "1.6em",
                          }}
                        >
                          <DiGit />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#D94128",
                            size: "1.4em",
                          }}
                        >
                          <AiFillGitlab />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part II
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Git + GitLab
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#EFD81B",
                          }}
                        >
                          <DiJsBadge />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#7012F4",
                            size: "1.4em",
                          }}
                        >
                          <DiBootstrap />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part III
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          JavaScript + Bootstrap 5
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part III Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML / CSS / JavaScript / Bootstrap 5
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#5FA04F",
                            size: "1.8em",
                          }}
                        >
                          <DiNodejsSmall />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            size: "1.8em",
                          }}
                        >
                          <SiExpress />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part IV
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Basic Unix, SQL, NodeJS, ExpressJS
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <div className="timeline-block">
                        <span className="timeline-step">
                          <AiFillCode />
                        </span>
                        <div className="timeline-content">
                          <h6 className="text-dark text-sm font-weight-bold mb-0">
                            Part IV Project
                          </h6>
                          <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                            SQL / NodeJS / ExpressJS / Javascript
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#5ED3F3",
                          }}
                        >
                          <DiReact />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part V
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          ReactJS + React Hooks + React Routing and Styling +
                          JSON
                        </p>
                      </div>
                    </div>
                    {/* <div className="timeline-block mb-3">
                    <span className="timeline-step">
                      <AiFillCode />
                    </span>
                    <div className="timeline-content">
                      <h6 className="text-dark text-sm font-weight-bold mb-0">
                        Part V Project
                      </h6>
                      <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                        ReactJS / SQL / JSON / Scripting languages
                      </p>
                    </div>
                  </div> */}
                    {/* <div className="timeline-block mb-3">
                    <span className="timeline-step">
                      <IconContext.Provider
                        value={{
                          color: "#31658C",
                          size: "1.8em",
                        }}
                      >
                        <DiPostgresql />
                      </IconContext.Provider>
                      <IconContext.Provider
                        value={{
                          color: "#2863CD",
                          size: "2em",
                        }}
                      >
                        <DiDocker />
                      </IconContext.Provider>
                    </span>
                    <div className="timeline-content">
                      <h6 className="text-dark text-sm font-weight-bold mb-0">
                        Part VI
                      </h6>
                      <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                        Relational Database Design, PostgreSQL, Docker,
                        Kubernetes
                      </p>
                    </div>
                  </div> */}
                    {/* <div className="timeline-block mb-3">
                    <span className="timeline-step">
                      <AiFillCode />
                    </span>
                    <div className="timeline-content">
                      <h6 className="text-dark text-sm font-weight-bold mb-0">
                        Part VI Project
                      </h6>
                      <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                        Full-stack Web Development
                      </p>
                    </div>
                  </div> */}
                    {/* <div className="timeline-block mb-3">
                    <span className="timeline-step">
                      <DiBrackets />
                    </span>
                    <div className="timeline-content">
                      <h6 className="text-dark text-sm font-weight-bold mb-0">
                        Part VII
                      </h6>
                      <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                        Object-Oriented Programming
                      </p>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-sm-4 mt-lg-0">
              <div className="card h-100">
                <div className="card-header pb-0">
                  <h6>Lingki</h6>
                  <p className="text-sm">
                    <span className="font-weight-bold">Started:</span> Jan. 3,
                    2023
                    <br />
                    <span className="font-weight-bold">Status:</span>{" "}
                    <span style={{ color: "green" }}>Active</span>
                  </p>
                </div>
                <div className="card-body p-3">
                  <div className="timeline timeline-one-side">
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#DC4B24",
                          }}
                        >
                          <DiHtml5 />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#0A6BB4",
                          }}
                        >
                          <DiCss3 />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML + CSS
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML / CSS Project
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#E84E31",
                            size: "1.6em",
                          }}
                        >
                          <DiGit />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#D94128",
                            size: "1.4em",
                          }}
                        >
                          <AiFillGitlab />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part II
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Git + GitLab
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#EFD81B",
                          }}
                        >
                          <DiJsBadge />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#7012F4",
                            size: "1.4em",
                          }}
                        >
                          <DiBootstrap />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part III
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          JavaScript + Bootstrap 5
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part III Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML / CSS / JavaScript / Bootstrap 5
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#5FA04F",
                            size: "1.8em",
                          }}
                        >
                          <DiNodejsSmall />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            size: "1.8em",
                          }}
                        >
                          <SiExpress />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part IV
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Basic Unix, SQL, NodeJS, ExpressJS
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <div className="timeline-block">
                        <span className="timeline-step">
                          <AiFillCode />
                        </span>
                        <div className="timeline-content">
                          <h6 className="text-dark text-sm font-weight-bold mb-0">
                            Part IV Project
                          </h6>
                          <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                            SQL / NodeJS / ExpressJS / Javascript
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#5ED3F3",
                            // size: "1.4em",
                          }}
                        >
                          <DiReact />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part V
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          ReactJS + React Hooks + React Routing and Styling +
                          JSON
                        </p>
                      </div>
                    </div>
                    {/* <div className="timeline-block mb-3">
                    <span className="timeline-step">
                      <AiFillCode />
                    </span>
                    <div className="timeline-content">
                      <h6 className="text-dark text-sm font-weight-bold mb-0">
                        Part V Project
                      </h6>
                      <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                        ReactJS / SQL / JSON / Scripting languages
                      </p>
                    </div>
                  </div> */}
                    {/* <div className="timeline-block mb-3">
                    <span className="timeline-step">
                      <IconContext.Provider
                        value={{
                          color: "#31658C",
                          size: "1.8em",
                        }}
                      >
                        <DiPostgresql />
                      </IconContext.Provider>
                      <IconContext.Provider
                        value={{
                          color: "#2863CD",
                          size: "2em",
                        }}
                      >
                        <DiDocker />
                      </IconContext.Provider>
                    </span>
                    <div className="timeline-content">
                      <h6 className="text-dark text-sm font-weight-bold mb-0">
                        Part VI
                      </h6>
                      <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                        Relational Database Design, PostgreSQL, Docker,
                        Kubernetes
                      </p>
                    </div>
                  </div> */}
                    {/* <div className="timeline-block mb-3">
                    <span className="timeline-step">
                      <AiFillCode />
                    </span>
                    <div className="timeline-content">
                      <h6 className="text-dark text-sm font-weight-bold mb-0">
                        Part VI Project
                      </h6>
                      <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                        Full-stack Web Development
                      </p>
                    </div>
                  </div> */}
                    {/* <div className="timeline-block mb-3">
                    <span className="timeline-step">
                      <DiBrackets />
                    </span>
                    <div className="timeline-content">
                      <h6 className="text-dark text-sm font-weight-bold mb-0">
                        Part VII
                      </h6>
                      <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                        Object-Oriented Programming
                      </p>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row mt-4">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card h-100">
                <div className="card-header pb-0">
                  <h6>Arnold</h6>
                  <p className="text-sm">
                    <span className="font-weight-bold">Started:</span> Feb. 1,
                    2023
                    <br />
                    <span className="font-weight-bold">Status:</span>{" "}
                    <span style={{ color: "red" }}>Inactive</span>
                  </p>
                </div>
                <div className="card-body p-3">
                  <div className="timeline timeline-one-side">
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#DC4B24",
                          }}
                        >
                          <DiHtml5 />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#0A6BB4",
                          }}
                        >
                          <DiCss3 />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML + CSS
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card h-100">
                <div className="card-header pb-0">
                  <h6>Paulhyn</h6>
                  <p className="text-sm">
                    <span className="font-weight-bold">Started:</span> Apr. 27,
                    2023
                    <br />
                    <span className="font-weight-bold">Status:</span>{" "}
                    <span style={{ color: "red" }}>Inactive</span>
                  </p>
                </div>
                <div className="card-body p-3">
                  <div className="timeline timeline-one-side">
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#DC4B24",
                          }}
                        >
                          <DiHtml5 />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#0A6BB4",
                          }}
                        >
                          <DiCss3 />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML + CSS
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-sm-4 mt-lg-0">
              <div className="card h-100">
                <div className="card-header pb-0">
                  <h6>Aerielle</h6>
                  <p className="text-sm">
                    <span className="font-weight-bold">Started:</span> Mar. 9,
                    2023
                    <br />
                    <span className="font-weight-bold">Status:</span>{" "}
                    <span style={{ color: "red" }}>Inactive</span>
                  </p>
                </div>
                <div className="card-body p-3">
                  <div className="timeline timeline-one-side">
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#DC4B24",
                          }}
                        >
                          <DiHtml5 />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#0A6BB4",
                          }}
                        >
                          <DiCss3 />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML + CSS
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML / CSS Project
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-sm-4 mt-lg-0">
              <div className="card h-100">
                <div className="card-header pb-0">
                  <h6>Karl</h6>
                  <p className="text-sm">
                    <span className="font-weight-bold">Started:</span> Mar. 22,
                    2023
                    <br />
                    <span className="font-weight-bold">Status:</span>{" "}
                    <span style={{ color: "red" }}>Inactive</span>
                  </p>
                </div>
                <div className="card-body p-3">
                  <div className="timeline timeline-one-side">
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#DC4B24",
                          }}
                        >
                          <DiHtml5 />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#0A6BB4",
                          }}
                        >
                          <DiCss3 />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML + CSS
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML / CSS Project
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="row mt-4">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card h-100">
                <div className="card-header pb-0">
                  <h6>Jaemily</h6>
                  <p className="text-sm">
                    <span className="font-weight-bold">Started:</span> Jul. 12,
                    2023
                    <br />
                    <span className="font-weight-bold">Status:</span>{" "}
                    <span style={{ color: "green" }}>Active</span>
                  </p>
                </div>
                <div className="card-body p-3">
                  <div className="timeline timeline-one-side">
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#DC4B24",
                          }}
                        >
                          <DiHtml5 />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#0A6BB4",
                          }}
                        >
                          <DiCss3 />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML + CSS
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML / CSS Project
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#E84E31",
                            size: "1.6em",
                          }}
                        >
                          <DiGit />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#D94128",
                            size: "1.4em",
                          }}
                        >
                          <AiFillGitlab />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part II
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Git + GitLab
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#EFD81B",
                          }}
                        >
                          <DiJsBadge />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#7012F4",
                            size: "1.4em",
                          }}
                        >
                          <DiBootstrap />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part III
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          JavaScript + Bootstrap 5
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part III Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML / CSS / JavaScript / Bootstrap 5
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#5FA04F",
                            size: "1.8em",
                          }}
                        >
                          <DiNodejsSmall />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            size: "1.8em",
                          }}
                        >
                          <SiExpress />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part IV
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Basic Unix, SQL, NodeJS, ExpressJS
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <div className="timeline-block">
                        <span className="timeline-step">
                          <AiFillCode />
                        </span>
                        <div className="timeline-content">
                          <h6 className="text-dark text-sm font-weight-bold mb-0">
                            Part IV Project
                          </h6>
                          <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                            SQL / NodeJS / ExpressJS / Javascript
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#5ED3F3",
                            // size: "1.4em",
                          }}
                        >
                          <DiReact />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part V
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          ReactJS + React Hooks + React Routing and Styling +
                          JSON
                        </p>
                      </div>
                    </div> */}
                    {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part V Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          ReactJS / SQL / JSON / Scripting languages
                        </p>
                      </div>
                    </div> */}
                    {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#31658C",
                            size: "1.8em",
                          }}
                        >
                          <DiPostgresql />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#2863CD",
                            size: "2em",
                          }}
                        >
                          <DiDocker />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part VI
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Relational Database Design, PostgreSQL, Docker,
                          Kubernetes
                        </p>
                      </div>
                    </div> */}
                    {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part VI Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Full-stack Web Development
                        </p>
                      </div>
                    </div> */}
                    {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <DiBrackets />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part VII
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Object-Oriented Programming
                        </p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card h-100">
                <div className="card-header pb-0">
                  <h6>Chris</h6>
                  <p className="text-sm">
                    <span className="font-weight-bold">Started:</span> Aug. 11,
                    2023
                    <br />
                    <span className="font-weight-bold">Status:</span>{" "}
                    <span style={{ color: "green" }}>Active</span>
                  </p>
                </div>
                <div className="card-body p-3">
                  <div className="timeline timeline-one-side">
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#DC4B24",
                          }}
                        >
                          <DiHtml5 />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#0A6BB4",
                          }}
                        >
                          <DiCss3 />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML + CSS
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML / CSS Project
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#E84E31",
                            size: "1.6em",
                          }}
                        >
                          <DiGit />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#D94128",
                            size: "1.4em",
                          }}
                        >
                          <AiFillGitlab />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part II
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Git + GitLab
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#EFD81B",
                          }}
                        >
                          <DiJsBadge />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#7012F4",
                            size: "1.4em",
                          }}
                        >
                          <DiBootstrap />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part III
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          JavaScript + Bootstrap 5
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part III Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML / CSS / JavaScript / Bootstrap 5
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#5FA04F",
                            size: "1.8em",
                          }}
                        >
                          <DiNodejsSmall />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            size: "1.8em",
                          }}
                        >
                          <SiExpress />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part IV
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Basic Unix, SQL, NodeJS, ExpressJS
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <div className="timeline-block">
                        <span className="timeline-step">
                          <AiFillCode />
                        </span>
                        <div className="timeline-content">
                          <h6 className="text-dark text-sm font-weight-bold mb-0">
                            Part IV Project
                          </h6>
                          <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                            SQL / NodeJS / ExpressJS / Javascript
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#5ED3F3",
                            // size: "1.4em",
                          }}
                        >
                          <DiReact />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part V
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          ReactJS + React Hooks + React Routing and Styling +
                          JSON
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part V Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          ReactJS / SQL / JSON / Scripting languages
                        </p>
                      </div>
                    </div>
                    {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#31658C",
                            size: "1.8em",
                          }}
                        >
                          <DiPostgresql />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#2863CD",
                            size: "2em",
                          }}
                        >
                          <DiDocker />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part VI
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Relational Database Design, PostgreSQL, Docker,
                          Kubernetes
                        </p>
                      </div>
                    </div> */}
                    {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part VI Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Full-stack Web Development
                        </p>
                      </div>
                    </div> */}
                    {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <DiBrackets />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part VII
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Object-Oriented Programming
                        </p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card h-100">
                <div className="card-header pb-0">
                  <h6>Ramla</h6>
                  <p className="text-sm">
                    <span className="font-weight-bold">Started:</span> Dec. 9,
                    2023
                    <br />
                    <span className="font-weight-bold">Status:</span>{" "}
                    <span style={{ color: "green" }}>Active</span>
                  </p>
                </div>
                <div className="card-body p-3">
                  <div className="timeline timeline-one-side">
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#DC4B24",
                          }}
                        >
                          <DiHtml5 />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#0A6BB4",
                          }}
                        >
                          <DiCss3 />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML + CSS
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML / CSS Project
                        </p>
                      </div>
                    </div>
                    {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#E84E31",
                            size: "1.6em",
                          }}
                        >
                          <DiGit />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#D94128",
                            size: "1.4em",
                          }}
                        >
                          <AiFillGitlab />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part II
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Git + GitLab
                        </p>
                      </div>
                    </div> */}
                    {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#EFD81B",
                          }}
                        >
                          <DiJsBadge />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#7012F4",
                            size: "1.4em",
                          }}
                        >
                          <DiBootstrap />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part III
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          JavaScript + Bootstrap 5
                        </p>
                      </div>
                    </div> */}
                    {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part III Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML / CSS / JavaScript / Bootstrap 5
                        </p>
                      </div>
                    </div> */}
                    {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#5FA04F",
                            size: "1.8em",
                          }}
                        >
                          <DiNodejsSmall />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            size: "1.8em",
                          }}
                        >
                          <SiExpress />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part IV
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Basic Unix, SQL, NodeJS, ExpressJS
                        </p>
                      </div>
                    </div> */}
                    {/* <div className="timeline-block mb-3">
                      <div className="timeline-block">
                        <span className="timeline-step">
                          <AiFillCode />
                        </span>
                        <div className="timeline-content">
                          <h6 className="text-dark text-sm font-weight-bold mb-0">
                            Part IV Project
                          </h6>
                          <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                            SQL / NodeJS / ExpressJS / Javascript
                          </p>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#5ED3F3",
                          }}
                        >
                          <DiReact />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part V
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          ReactJS + React Hooks + React Routing and Styling +
                          JSON
                        </p>
                      </div>
                    </div> */}
                    {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part V Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          ReactJS / SQL / JSON / Scripting languages
                        </p>
                      </div>
                    </div> */}
                    {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#31658C",
                            size: "1.8em",
                          }}
                        >
                          <DiPostgresql />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#2863CD",
                            size: "2em",
                          }}
                        >
                          <DiDocker />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part VI
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Relational Database Design, PostgreSQL, Docker,
                          Kubernetes
                        </p>
                      </div>
                    </div> */}
                    {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part VI Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Full-stack Web Development
                        </p>
                      </div>
                    </div> */}
                    {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                      
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part VII
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Object-Oriented Programming
                        </p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              {/* New DevJedi progress tracker starting - start */}

              {/* <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="card h-100">
                  <div className="card-header pb-0">
                    <h6>Dev Jedi Name</h6>
                    <p className="text-sm">
                      <span className="font-weight-bold">Started:</span> Jan.
                      01, 2024
                      <br />
                      <span className="font-weight-bold">Status:</span>{" "}
                      <span style={{ color: "green" }}>Active</span>
                    </p>
                  </div>
                  <div className="card-body p-3">
                    <div className="timeline timeline-one-side"> */}

              {/* New DevJedi progress tracker starting - end */}

              {/* <div className="timeline-block mb-3">
                        <span className="timeline-step">
                          <IconContext.Provider
                            value={{
                              color: "#DC4B24",
                            }}
                          >
                            <DiHtml5 />
                          </IconContext.Provider>
                          <IconContext.Provider
                            value={{
                              color: "#0A6BB4",
                            }}
                          >
                            <DiCss3 />
                          </IconContext.Provider>
                        </span>
                        <div className="timeline-content">
                          <h6 className="text-dark text-sm font-weight-bold mb-0">
                            Part I
                          </h6>
                          <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                            HTML + CSS
                          </p>
                        </div>
                      </div> */}
              {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML / CSS Project
                        </p>
                      </div>
                    </div> */}
              {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#E84E31",
                            size: "1.6em",
                          }}
                        >
                          <DiGit />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#D94128",
                            size: "1.4em",
                          }}
                        >
                          <AiFillGitlab />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part II
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Git + GitLab
                        </p>
                      </div>
                    </div> */}
              {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#EFD81B",
                          }}
                        >
                          <DiJsBadge />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#7012F4",
                            size: "1.4em",
                          }}
                        >
                          <DiBootstrap />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part III
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          JavaScript + Bootstrap 5
                        </p>
                      </div>
                    </div> */}
              {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part III Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML / CSS / JavaScript / Bootstrap 5
                        </p>
                      </div>
                    </div> */}
              {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#5FA04F",
                            size: "1.8em",
                          }}
                        >
                          <DiNodejsSmall />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            size: "1.8em",
                          }}
                        >
                          <SiExpress />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part IV
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Basic Unix, SQL, NodeJS, ExpressJS
                        </p>
                      </div>
                    </div> */}
              {/* <div className="timeline-block mb-3">
                      <div className="timeline-block">
                        <span className="timeline-step">
                          <AiFillCode />
                        </span>
                        <div className="timeline-content">
                          <h6 className="text-dark text-sm font-weight-bold mb-0">
                            Part IV Project
                          </h6>
                          <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                            SQL / NodeJS / ExpressJS / Javascript
                          </p>
                        </div>
                      </div>
                    </div> */}
              {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#5ED3F3",
                          }}
                        >
                          <DiReact />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part V
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          ReactJS + React Hooks + React Routing and Styling +
                          JSON
                        </p>
                      </div>
                    </div> */}
              {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part V Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          ReactJS / SQL / JSON / Scripting languages
                        </p>
                      </div>
                    </div> */}
              {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#31658C",
                            size: "1.8em",
                          }}
                        >
                          <DiPostgresql />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#2863CD",
                            size: "2em",
                          }}
                        >
                          <DiDocker />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part VI
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Relational Database Design, PostgreSQL, Docker,
                          Kubernetes
                        </p>
                      </div>
                    </div> */}
              {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part VI Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Full-stack Web Development
                        </p>
                      </div>
                    </div> */}
              {/* <div className="timeline-block mb-3">
                      <span className="timeline-step">
                      
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part VII
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Object-Oriented Programming
                        </p>
                      </div>
                    </div> */}

              {/* New DevJedi progress tracker closing - start */}

              {/* </div>
                  </div> 
                </div>
              </div> */}

              {/* New DevJedi progress tracker closing - start */}
            </div>
            {/* <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card h-100">
                <div className="card-header pb-0">
                  <h6>Full Development Path</h6>
                </div>
                <div className="card-body p-3">
                  <div className="timeline timeline-one-side">
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#DC4B24",
                          }}
                        >
                          <DiHtml5 />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#0A6BB4",
                          }}
                        >
                          <DiCss3 />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML + CSS
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part I Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML / CSS Project
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#E84E31",
                            size: "1.6em",
                          }}
                        >
                          <DiGit />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#D94128",
                            size: "1.4em",
                          }}
                        >
                          <AiFillGitlab />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part II
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Git + GitLab
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#EFD81B",
                          }}
                        >
                          <DiJsBadge />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#7012F4",
                            size: "1.4em",
                          }}
                        >
                          <DiBootstrap />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part III
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          JavaScript + Bootstrap 5
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part III Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          HTML / CSS / JavaScript / Bootstrap 5
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#5FA04F",
                            size: "1.8em",
                          }}
                        >
                          <DiNodejsSmall />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            size: "1.8em",
                          }}
                        >
                          <SiExpress />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part IV
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Basic Unix, SQL, NodeJS, ExpressJS
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <div className="timeline-block">
                        <span className="timeline-step">
                          <AiFillCode />
                        </span>
                        <div className="timeline-content">
                          <h6 className="text-dark text-sm font-weight-bold mb-0">
                            Part IV Project
                          </h6>
                          <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                            SQL / NodeJS / ExpressJS / Javascript
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#5ED3F3",
                          }}
                        >
                          <DiReact />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part V
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          ReactJS + React Hooks + React Routing and Styling +
                          JSON
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part V Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          ReactJS / SQL / JSON / Scripting languages
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <IconContext.Provider
                          value={{
                            color: "#31658C",
                            size: "1.8em",
                          }}
                        >
                          <DiPostgresql />
                        </IconContext.Provider>
                        <IconContext.Provider
                          value={{
                            color: "#2863CD",
                            size: "2em",
                          }}
                        >
                          <DiDocker />
                        </IconContext.Provider>
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part VI
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Relational Database Design, PostgreSQL, Docker,
                          Kubernetes
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <AiFillCode />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part VI Project
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Full-stack Web Development
                        </p>
                      </div>
                    </div>
                    <div className="timeline-block mb-3">
                      <span className="timeline-step">
                        <DiBrackets />
                      </span>
                      <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">
                          Part VII
                        </h6>
                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                          Object-Oriented Programming
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <FooterSection />
      </main>
    </>
  );
};

export default TraineeProgressTracker;
