import { Link } from "react-router-dom";

import TopNav from "../components/topNav";
import SideNav from "../components/sideNav";
import Footer from "../components/footer";

import { IconContext } from "react-icons";
import {
  FcReadingEbook,
  FcMultipleDevices,
  FcSurvey,
  FcCalendar,
  FcAreaChart,
  FcOvertime,
  FcTodoList,
} from "react-icons/fc";

const Dashboard = () => {
  return (
    <>
      <SideNav />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <TopNav page={"Dashboard"} />
        <div className="container-fluid">
          <h1 className="fs-5 text-dark">Dashboard</h1>
          <div className="container-fluid py-2">
            <div className="row mt-4 text-center">
              <div className="col-lg-3 col-md-6">
                <Link to="/dashboard/projects">
                  <IconContext.Provider
                    value={{
                      size: "2em",
                    }}
                  >
                    <FcMultipleDevices />
                  </IconContext.Provider>
                  <h2 className="fs-5">Projects</h2>
                </Link>
                <p>
                  The Project Tracker is a central hub for our project tracking.
                  It offers a project list, assignments and project status.
                </p>
                <p>
                  Go to{" "}
                  <Link
                    to="/dashboard/projects"
                    className="text-decoration-underline"
                  >
                    Projects
                  </Link>{" "}
                  page
                </p>
              </div>
              <div className="col-lg-3 col-md-6">
                <Link to="/dashboard/notes">
                  <IconContext.Provider
                    value={{
                      size: "2em",
                    }}
                  >
                    <FcSurvey />
                  </IconContext.Provider>
                  <h2 className="fs-5">Meeting Notes</h2>
                </Link>
                <p>
                  The meeting notes page has the compiling record of our
                  discussions, decisions and action items from our meetings.
                </p>
                <p>
                  Go to{" "}
                  <Link
                    to="/dashboard/notes"
                    className="text-decoration-underline"
                  >
                    Notes
                  </Link>{" "}
                  page
                </p>
              </div>
              <div className="col-lg-3 col-md-6">
                <Link to="/dashboard/calendar">
                  <IconContext.Provider
                    value={{
                      size: "2em",
                    }}
                  >
                    <FcCalendar />
                  </IconContext.Provider>
                  <h2 className="fs-5">Calendar</h2>
                </Link>
                <p>
                  Centralized schedule tracking of time-sensitive tasks,
                  including SSL certificate renewals, to remind us of these
                  deadlines.
                </p>
                <p>
                  Go to{" "}
                  <Link
                    to="/dashboard/calendar"
                    className="text-decoration-underline"
                  >
                    Calendar
                  </Link>{" "}
                  page
                </p>
              </div>
              <div className="col-lg-3 col-md-6">
                <Link to="/dashboard/reports-and-analytics">
                  <IconContext.Provider
                    value={{
                      size: "2em",
                    }}
                  >
                    <FcAreaChart />
                  </IconContext.Provider>
                  <h2 className="fs-5">Reports</h2>
                </Link>
                <p>
                  The reports page has data-driven insights for our LinkedIn and
                  Facebook social media accounts showing overall performance.
                </p>
                <p>
                  Go to{" "}
                  <Link
                    to="/dashboard/reports-and-analytics"
                    className="text-decoration-underline"
                  >
                    Reports
                  </Link>{" "}
                  page
                </p>
              </div>
            </div>
            <div className="row mt-4 text-center">
              <div className="col-lg-4 col-md-6">
                <Link to="/dashboard/training/attendance">
                  <IconContext.Provider
                    value={{
                      size: "2em",
                    }}
                  >
                    <FcOvertime />
                  </IconContext.Provider>
                  <h2 className="fs-5">DevJedi Attendance</h2>
                </Link>
                <p>
                  DevJedi Attendance page has the records the DevJedi's presence
                  and absences in our weekly meetings. This page also a record
                  of DevJedi status and training start date.
                </p>
                <p>
                  Go to{" "}
                  <Link
                    to="/dashboard/training/attendance"
                    className="text-decoration-underline"
                  >
                    Attendance
                  </Link>{" "}
                  page
                </p>
              </div>
              <div className="col-lg-4 col-md-6">
                <Link to="/dashboard/training/progress-tracker">
                  <IconContext.Provider
                    value={{
                      size: "2em",
                    }}
                  >
                    <FcTodoList />
                  </IconContext.Provider>
                  <h2 className="fs-5">DevJedi Progress Tracker</h2>
                </Link>
                <p>
                  The DevJedi Progress tracker has a detailed overview of the
                  DevJedi's Software Engineer training program. It has the list
                  of topics, projects and milestones for web development skills.
                </p>
                <p>
                  Go to{" "}
                  <Link
                    to="/dashboard/training/progress-tracker"
                    className="text-decoration-underline"
                  >
                    Progress Tracker
                  </Link>{" "}
                  page
                </p>
              </div>
              <div className="col-lg-4 col-md-6">
                <Link to="/dashboard/training/projects">
                  <IconContext.Provider
                    value={{
                      size: "2em",
                    }}
                  >
                    <FcReadingEbook />
                  </IconContext.Provider>
                  <h2 className="fs-5">DevJedi Projects</h2>
                </Link>
                <p>
                  The DevJedi Projects page features a comprehensive compilation
                  of projects undertaken by DevJedi trainees, showcasing
                  assignments, status, and completion.
                </p>
                <p>
                  Go to{" "}
                  <Link
                    to="/dashboard/training/projects"
                    className="text-decoration-underline"
                  >
                    Projects
                  </Link>{" "}
                  page
                </p>
              </div>
            </div>
          </div>
          {/* <div className="container-fluid py-2">
            <div className="row">
              <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <div className="card">
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-8">
                        <div className="numbers">
                          <p className="text-sm mb-0 text-capitalize font-weight-bold mb-1">
                            Active DevJedis
                          </p>
                          <h5 className="font-weight-bolder mb-0">
                            12
                            <span className="text-success text-sm font-weight-bolder">
                              &nbsp;+1 last week
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="col-4 text-end">
                        <div className="icon icon-shape icon-td bg-gradient-primary shadow text-center border-radius-md pt-2">
                          <a
                            href="https://t.me/+ut0icVlW8wFjNGJh"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IconContext.Provider
                              value={{
                                size: "1.3em",
                                color: "white",
                              }}
                            >
                              <FaUsers />
                            </IconContext.Provider>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <div className="card">
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-8">
                        <div className="numbers">
                          <p className="text-sm mb-0 text-capitalize font-weight-bold mb-1">
                            Social Media
                          </p>
                          <h5 className="font-weight-bolder mb-0">
                            2
                            <span className="text-success text-sm font-weight-bolder">
                              &nbsp; this week
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="col-4 text-end">
                        <div className="icon icon-shape icon-td bg-gradient-primary shadow text-center border-radius-md pt-2">
                          <a
                            href="https://www.linkedin.com/company/trivut-corp/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IconContext.Provider
                              value={{
                                size: "1.5em",
                                color: "white",
                              }}
                            >
                              <BiLike />
                            </IconContext.Provider>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <div className="card">
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-8">
                        <div className="numbers">
                          <p className="text-sm mb-0 text-capitalize font-weight-bold mb-1">
                            LinkedIn
                          </p>
                          <h5 className="font-weight-bolder mb-0 mr-1">
                            187
                            <span className="text-success text-sm font-weight-bolder">
                              &nbsp;+5 this week
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="col-4 text-end">
                        <div className="icon icon-shape icon-td bg-gradient-primary shadow text-center border-radius-md pt-2">
                          <a
                            href="https://www.linkedin.com/company/trivut-corp/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IconContext.Provider
                              value={{
                                size: "1.5em",
                                color: "white",
                              }}
                            >
                              <AiFillLinkedin />
                            </IconContext.Provider>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div className="card">
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-8">
                        <div className="numbers">
                          <p className="text-sm mb-0 text-capitalize font-weight-bold mb-1">
                            Facebook
                          </p>
                          <h5 className="font-weight-bolder mb-0">
                            66
                            <span className="text-success text-sm font-weight-bolder">
                              &nbsp;+3 this week
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="col-4 text-end">
                        <div className="icon icon-shape icon-td bg-gradient-primary shadow text-center border-radius-md pt-2">
                          <a
                            href="https://web.facebook.com/profile.php?id=100086523165246"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IconContext.Provider
                              value={{
                                size: "1.4em",
                                color: "white",
                              }}
                            >
                              <FaFacebook />
                            </IconContext.Provider>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Dashboard;
