import emailAddress2 from "../img/about/email4.webp";

import { IconContext } from "react-icons";
import { BsFacebook } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsTwitterX } from "react-icons/bs";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer-area">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="footer-content">
                  <div className="footer-head">
                    <div className="footer-logo">
                      <h2>Our Mission</h2>
                    </div>
                    <p>
                      To develop innovative application software and
                      technologies that suit your needs in order to harness the
                      power and advantages of our interconnected world. Our end
                      products transform and digitize data, automating their
                      processes and services using sophisticated machine
                      learning algorithms and artificial intelligence.
                    </p>
                    <div className="footer-icons">
                      {/* Stay Connected with Us: */}
                      Follow Us
                      <ul className="mt-2">
                        <li>
                          <a
                            href="https://web.facebook.com/profile.php?id=100086523165246"
                            rel="noreferrer"
                            target="_blank"
                            aria-label="Facebook"
                          >
                            <IconContext.Provider value={{ size: "16px" }}>
                              <BsFacebook />
                            </IconContext.Provider>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/trivut-corp/"
                            rel="noreferrer"
                            target="_blank"
                            aria-label="LinkedIn"
                          >
                            <IconContext.Provider value={{ size: "16px" }}>
                              <BsLinkedin />
                            </IconContext.Provider>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://x.com/TrivutCorp"
                            rel="noreferrer"
                            target="_blank"
                            aria-label="X"
                          >
                            <IconContext.Provider value={{ size: "16px" }}>
                              <BsTwitterX />
                            </IconContext.Provider>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer-content">
                  <div className="footer-head">
                    <h3>&nbsp;</h3>
                    <span className="fs-6 fw-bolder">Trivut Corp.</span>
                    <br />
                    <p>DIGITAL TRANSFORMATION AND INNOVATION</p>
                    <div className="footer-contacts">
                      <p>
                        <span>Contact:</span> +63 961 560 1730
                      </p>
                      <p>
                        <span>Email: </span>
                        <img
                          src={emailAddress2}
                          alt="our email address"
                          width="113px"
                          height="17px"
                          className="pl-2"
                          style={{ paddingBottom: "2px" }}
                        />
                      </p>
                      <p>
                        <span>Office Hours:</span> 8am-5pm
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="footer-content">
                  <div className="footer-head">
                    {/* <h4>Site Links</h4> */}
                    <h4>&nbsp;</h4>
                    <div className="row">
                      <div className="col-md-6">
                        <p>
                          <a href="/outsourcing" rel="noreferrer">
                            Why Outsource?
                          </a>
                        </p>
                        <p>
                          <a href="/our-expertise" rel="noreferrer">
                            Our Expertise
                          </a>
                        </p>
                        <p>
                          <a href="/careers" rel="noreferrer">
                            Careers
                          </a>
                        </p>
                        <p>
                          <a href="#contact">Contact Us</a>
                        </p>
                        <p>
                          <a href="#contact">Request a Demo</a>
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p>
                          <a href="/terms-and-conditions" rel="noreferrer">
                            Terms and Conditions
                          </a>
                        </p>
                        <p>
                          <a href="/privacy-policy" rel="noreferrer">
                            Privacy Policy
                          </a>
                        </p>
                        {/* <p>
                          <a href="/investor-relations" rel="noreferrer">
                            Investor Center
                          </a>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-area-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="copyright text-center">
                  <p>
                    &copy; Copyright {new Date().getFullYear()}{" "}
                    <strong>Trivut Corp.</strong> All Rights Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* <div id="preloader"></div> */}
      <a
        href="#hero"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
};

export default Footer;
