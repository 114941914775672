import trivutImg2 from "../img/about/2.webp";

const MarketingSectionBig = () => {
  return (
    <>
      <div className="reviews-area">
        <div className="row g-0">
          <div className="col-lg-6 d-md-none d-lg-block">
            <img
              src={trivutImg2}
              alt="hands together"
              className="img-fluid h-100"
            />
          </div>
          <div className="col-lg-6 work-right-text d-flex align-items-center">
            <div className="px-5 py-5 py-lg-0">
              <h4 className="text-white">Partner with us</h4>
              <h5 style={{ lineHeight: "28px" }}>
                Accelerate your software development with our expert team.
                Partner with us to build innovative solutions that drive your
                business forward.
              </h5>
              <a href="#contact" className="ready-btn scrollto">
                Get in Touch
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingSectionBig;
